#back-plans-header {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 4rem;
}

#back-plans-header .btn-wrapper {
  width: 50%;
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 2rem;
}

#back-plans-header .blank-div {
  width: 50%;
  height: 4rem;
  background-color: #ffffff;
}

#checkout-form-ctn {
  width: 50%;
  height: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding-top: 4vh;
  padding-left: 3vw;
}

@media (min-width: 1536px) {
  #checkout-form-ctn {
    padding-top: 10vh;
  }
}

#checkout-form-ctn .checkout-right-wrapper {
  width: 90%;
  max-width: 400px;
}

#checkout-form-ctn .payment-info {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 20px;
  margin-bottom: 0.75rem;
}

#checkout-form-ctn .checkout-right-wrapper h1 {
  font-size: 1.5rem;
}

#checkout-form-ctn .checkout-email-ctn {
  margin-bottom: 1rem;
}

#checkout-form-ctn .checkout-form-bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
}

#checkout-form-ctn .form-group input {
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
}

.mycustom-select .my-select {
  border: 1px solid #d5d7e4;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url('../../../../../public/assets/images/cv-down.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 10px;
  padding-right: 30px;
  width: 100%;
}

#pay-ctn {
  width: 100%;
  margin-top: 1rem;
}

#pay-ctn .pay-wrapper {
  width: 100%;
}

#pay-ctn .pay-wrapper img {
  margin-right: 0.5rem;
  width: 0.75rem;
  padding-bottom: 0.1rem;
}

#pay-ctn .pay-wrapper .btn-content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

#bottom-infos {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#bottom-infos .infos {
  font-size: 0.75rem;
  color: #838383;
  text-align: center;
}

#bottom-infos .infos .info-text {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  gap: 0.25rem;
  font-style: normal;
  /* background-color: red; */
}

#bottom-infos .info-text img {
  position: absolute;
  top: 0.25rem;
  left: 0;
}

#bottom-infos .links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: #838383;
  font-size: 0.95rem;
}

#bottom-infos .links .terms {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding-left: 1rem;
  border-left: 1px solid #838383;
}

#bottom-infos .links .terms a {
  font-size: 0.95rem;
  color: #838383;
}

#bottom-infos .links .terms a:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
}

#checkout-form-ctn .card-detail-ui .form-group input {
  background-color: #ffffff;
}

#checkout-form-ctn .border-style {
  border-radius: 10px;
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  padding: 0.75rem 1rem;
  border: 1px solid #d5d7e4;
  background-color: #ffffff;
}

#checkout-form-ctn .vat-number {
  width: 100%;
}

#is-update-payment-info {
  position: relative;
  padding-left: 2rem;
  width: 100%;
  height: 6rem;
}

#is-update-payment-info .back-home-btn {
  display: flex;
  align-items: start;
  position: relative;
}

#is-update-payment-info .logo-image {
  width: 100px;
  transition: opacity 0.3s ease;
  position: absolute;
  top: -5px;
}

#is-update-payment-info .back-text {
  position: absolute;
  left: 6.5rem;
  top: 0.75rem;
  transform: translate(-50%, -50%);
  opacity: 0;
  white-space: nowrap;
  transition: opacity 0.3s ease;
}

#is-update-payment-info .back-home-btn:hover .logo-image {
  opacity: 0;
}

#is-update-payment-info .back-home-btn:hover .back-text {
  opacity: 1;
}
