
.company-infos button {
    color: #4458C9 ;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 2px rgba(0,0,0,.2),0 0 2px rgba(0,0,0,.2),0 0 2px rgba(0,0,0,.1);
    font-weight: 500;
}
.company-infos .company-name-container> a {
    font-weight: 500;
    text-decoration: underline;
    font-size: 13px;
    color: #4458C9;
}
.company-infos hr {
    border-color: rgba(204, 204, 204, 0.32);
}
.company-infos .form-group{
    margin-bottom:20px !important;
    padding: 0;
}

.company-infos .company-name-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}
.company-infos .company-name-container p {
    font-weight: 500;
    display: flex;
    align-items: center;
}

.company-infos .company-name-container  .img-container {

    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0,0,0,.1);
    padding: 2px;
    overflow: hidden;
}
.company-infos .img-container img{
    width: 100%;
}

.company-infos .add-btn {
    width: 100px;
    color: white;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
}