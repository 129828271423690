#billing-nav-bar {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
}

#billing-nav-bar img {
  width: 16px;
  height: 16px;
}

#billing-nav-bar p,
#billing-nav-bar a {
  font-size: 14px;
  font-weight: 500;
  color: #475467;
}

#billing-nav-bar .customize {
  color: #4d58f3;
}
