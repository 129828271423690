.tags-container {
  border-left: 1px solid #ccc;
}
#tags_list {
  height: 37px;
}
#tags_list .add-tag-btn {
  cursor: pointer;
  min-width: max-content;
  height: 32px;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  align-items: center;
}
#tags_list .dropdown-toggle {
  width: auto !important;
  height: 32px !important;
  padding: 0;
  padding-left: 10px;
}
#tags_list .dropdown-toggle::after {
  display: none !important;
}
#tags_list .tags-list-select {
  height: 400px;
  padding: 0px;

}

#tags_list #search-tag-input {
  display: flex;

  background: #f3f3f4;
  border-radius: 5px;
  padding: 5px;
  position: relative;
  border: 1px dashed #ccc;
  width: 280px;
}
#tags_list .tags-list-select .add-tag {
  color: #566ff4;
  border: 1px dashed #8e96b6;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 10px;
  border-radius: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
#tags_list .tags-list-select .add-custom-tag {
  display: flex;

  background: #f3f3f4;
  border-radius: 5px;
  padding: 5px;
  position: relative;
  border: 1px dashed #ccc;
}
#tags_list .tags-list-select ul {
  overflow: auto;
  height: 300px;
  margin-top: 10px;
  padding-right: 10px;
}
#tags_list .tags-list-select .add-custom-tag input {
  border: none;
  background: transparent;
  width: 100%;
  font-size: 14px;
  padding-left: 5px;
}
#tags_list .tags-list-select .add-custom-tag .tag-colors {
  position: absolute;
  top: 45px;
  right: 0;
  width: 100%;
  height: 200px;
  z-index: 10;
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #f3f3f1;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}
#tags_list .tags-list-select .add-custom-tag .fa-sync {
  position: absolute;
  top: 7px;
  right: 9px;
  font-size: 12px;
  cursor: pointer;
  color: #555;
}
#tags_list .tags-list-select .add-custom-tag .fa-times {
  position: absolute;
  top: 5px;
  right: 7px;
  font-size: 15px;
  cursor: pointer;
  color: darkred;
}
#tags_list .tags-list-select .add-custom-tag .tag-color {
  background: #ccc;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  margin-left: 2px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  border: 1px solid transparent;
}

#tags_list .tags-list-select .add-custom-tag .tag-colors .tag-color {
  margin: 1px 5px;
  height: 25px;
  width: 25px;
}
#tags_list .tags-list-select .add-custom-tag .tag-colors .tag-color:hover {
  border-color: #000;
}
#tags_list .tags-list-select .ui-tag-color {
  margin-top: 20px;
}

#tags_list .tags-list-select .ui-tag-color .tag-name,
.lead-tags-container > span {
  color: #000;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 10px;
  border-radius: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  background: #ccc;
  margin-right: 5px;
}
.lead-tags-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.lead-tags-container > span {
  margin: 5px;
  position: relative;
  cursor: default;
    user-select: none;
}
.lead-tags-container > span .fa-times {
  margin-left: 5px;
  margin-right: -5px;
  display: none;
  position: absolute;
  top: -4px;
  right: 0px;
  background: darkred;
  border-radius: 50%;
  padding:2px 4px;
  color: #fff;
}
.lead-tags-container > span:hover .fa-times {
  display: inline-block;
  cursor: pointer;
}
#tags_list .tags-list-select .ui-tag-color .tag-name > span {
  cursor: pointer;
}
#tags_list .manage-tag {
  position: absolute;
  top: -10px;
  right: -15px;
  background: #fff;
  border-radius: 10px;
  padding: 0px 2px;
  padding-bottom: 1px;
  border: 1px dashed #ccc;
  cursor: default;
  display: none;
}
#tags_list .manage-tag img {
  width: 12px;
  margin: 0px 2px;
  cursor: pointer;
}
#tags_list .manage-tag img:hover {
  filter: contrast(0%);
}
#tags_list .tags-list-select .ui-tag-color:hover .manage-tag {
  display: inline-block;
}
