#bulk-enrichment .modal-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

#bulk-enrichment .modal-container-top {
  display: flex;
  flex-direction: column;
}

#bulk-enrichment .modal-container h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  padding: 0 10px;
  margin-bottom: 20px;
}

#bulk-enrichment .modal-content-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 10px;
}

#bulk-enrichment .mapping-field {
  display: flex;
  flex-direction: column;
  border: 1px solid #f5f5f9;
  border-radius: 10px;
  padding: 10px;
  background-color: #f5f5f9;
}

#bulk-enrichment .mapping-field h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 10px;
}

#bulk-enrichment .modal-content-container-top,
#bulk-enrichment .modal-content-container-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#bulk-enrichment .modal-content-container-bottom .csv-field {
  display: flex;
  gap: 6px;
}

#bulk-enrichment .modal-content-container-bottom .asterisk {
  color: #595f79;
  font-size: 11px;
  display: flex;
  align-items: end;
  margin-bottom: 3px;
  font-weight: 400;
}

#bulk-enrichment .modal-content-container-bottom .linkedin-url-row {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: start;
}
#bulk-enrichment .modal-content-container-top h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.14px;
  color: #595f79;
}

#bulk-enrichment .lead-list {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f5f5f9;
  border-radius: 10px;
  padding: 10px;
  background-color: #f5f5f9;
  margin-bottom: 20px;
}

#bulk-enrichment .lead-list h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 0;
}

#bulk-enrichment .modal-container-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#bulk-enrichment .steps {
  display: flex;
}

#bulk-enrichment .steps i {
  margin-right: 3px;
}

#bulk-enrichment h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.28px;
  color: #111833;
}

#bulk-enrichment .progress-bar {
  width: 100%;
  height: 4px;
  border-radius: 5px;
}

#bulk-enrichment .progress-bar::-webkit-progress-bar {
  background-color: #e8eaf5;
}

#bulk-enrichment .progress-bar::-webkit-progress-value {
  background-color: #495377;
}

#bulk-enrichment .progress-bar::-moz-progress-bar {
  background-color: #495377;
}

#bulk-enrichment .modal-container-bottom-buttons button a {
  z-index: 0;
}

#bulk-enrichment .mapping-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

#bulk-mapping .error .button {
  border: 1px solid red;
}

#bulk-mapping .error-message {
  color: red;
}
