.connection-modal-right-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 28%;
  overflow-y: auto;
  padding: 0 8px 8px 0;
  scrollbar-width: none;
}

.linkedin-container-width {
  width: 38% !important;
}

.connection-modal-right-container::-webkit-scrollbar {
  display: none;
}

.connection-modal-right-container button {
  width: 85%;
  align-self: center;
}

.connection-modal-right-container h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0;
  color: #0d1021;
  padding-left: 15px;
}

.connection-modal-right-container .items-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
