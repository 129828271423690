.lead-changes-table {
  width: 100%;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 12px;
  color: #666;
  font-weight: 400;
}
.lead-changes-table td,
.lead-changes-table th {
  border: 1px solid #ccc;
  text-align: center;
}
