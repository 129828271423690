#modify-users {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#modify-users .modify-left-ctn {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

#modify-users .modify-left-ctn h2 {
  font-size: 16px;
  font-weight: 500;
  color: #0d1021;
}

#modify-users .modify-left-ctn h3 {
  font-size: 12px;
  font-weight: 400;
  color: #495377;
}

#modify-users .plan-ctn {
  display: flex;
  align-items: center;
  padding: 6px;
  border: 1px solid #e0e1e7;
  border-radius: 10px;
}

#modify-users .decrement-button,
#modify-users .increment-button {
  width: 25px;
  height: 25px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #e0e1e7;
}

#modify-users .number-of-seats {
  margin: 0 10px;
  font-size: 18px;
  font-size: 16px;
  font-weight: 500;
}

#modify-users .minus-stick,
#modify-users .plus-stick {
  background-color: #201d11;
  height: 2px;
  width: 12px;
  position: absolute;
}

#modify-users .plus-stick.vertical {
  transform: rotate(90deg);
}
