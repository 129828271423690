.pending-plan {
    margin-bottom:10px;
    background: #f7f7f7;
    padding:0 20px;
    border-radius: 10px;
    height: 60px;
    overflow: hidden;
    transition: all .3s ease ;
}
.pending-plan.expand{
    height: 400px;
}
.pending-plan p,
.pending-plan p span,
.pending-plan label{
    color: #555 !important;
}
.pending-plan .info-card-header{
    margin-bottom: 20px;
}
.plan-credits-rm {
    display: flex;
    flex-wrap: wrap;
    max-width: 85%;
}
.plan-credits-rm  span{
    background: #97979710;
    padding: 7px 15px;
    margin: 5px;
    display: block;
    border-radius: 30px;
    color: #999!important;
    font-size: 14px!important;
}
.plan-credits-rm  span strong {
    font-weight: 400;
    color: #222;
}
.billing .info-block {
   margin-bottom: 20px!important;
}

