#subscription-cancelled-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}

#subscription-cancelled-container img {
    height: 200px;
    width: auto;
}

#subscription-cancelled-container h1 {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.12px;
    color: #0D1021;
}

#subscription-cancelled-container h2 {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.12px;
    text-align: center;
    color: #979797;
}

#subscription-account-btn {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #D5D7E4;
}
