#let-us-help-modal .let-us-help-modal-title {
  display: flex;
  gap: 5px;
  align-items: center;
}

#let-us-help-modal .let-us-help-modal-title img {
  width: 20px;
  height: auto;
}

#let-us-help-modal .let-us-help-modal-title h2 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #4d58f3;
}

#let-us-help-modal h1 {
  font-size: 28px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: -0.12px;
  text-align: left;
  color: #0d1021;
  padding-top: 10px;
  margin: 0;
}

#let-us-help-modal p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: "#495377";
  margin: 20px 0;
}

#let-us-help-modal .buttons-container {
  width: 100%;
  justify-content: end;
  gap: 10px;
  padding-top: 14px;
  border-top: 1px solid #d5d7e4;
  margin-top: 14px;
}

#let-us-help-modal .buttons-container button {
  padding: 0 24px;
}
