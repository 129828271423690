#bulk-home .dropzone-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 30vh;
  background-color: #fafbff;
  border: 1px dashed #d1d7f6;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}
#bulk-home .dropzone {
  width: 100%;
  height: 100%;
}
#bulk-home .dropzone-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 10px;
}
#bulk-home .dropzone-content h2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
#bulk-home .dropzone-content p {
  color: #8c92ab;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

#bulk-home .dropzone-content:hover,
#bulk-home .dropzone-content.is-drag-active {
  border-radius: 10px;
  transform: scale(1.05);
}

#bulk-home .access-denied {
  cursor: not-allowed;
}

.hover-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: rgba(220, 53, 69, 0.9);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease;
}

.dropzone-container:hover .hover-container {
  visibility: visible;
  opacity: 1;
}

.hover-message {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

