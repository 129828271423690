/*
.custom-slide-animation {
    height : 0 ;
    overflow: hidden;
    padding: 0 !important;
    transition: all .5s ease-in;
}
.slide-down {
    height : 200px;
    padding: initial !important;
}*/
/*
.custom-slide-animation {
    transform-origin: top;
    transform : scale(1,0) ;
    transition: all .3s ease-in;
}
.slide-down {
    transform : scale(1,1) ;
}*/
.date-toggle-enter {
    opacity: 0;
    transform: scale(0.9);
}
.date-toggle-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.date-toggle-exit {
    opacity: 1;
}
.date-toggle-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}
