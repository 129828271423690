#bulk-enrichment .modal-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
}
#bulk-enrichment .modal-container-top {
  display: flex;
  flex-direction: column;
}
#bulk-enrichment .modal-container-top h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  padding: 0 10px;
}
#bulk-enrichment .enrichment-options-cards {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
#bulk-enrichment .mandatory-desc {
  display: flex;
  align-items: start;
  gap: 10px;
  padding: 0 20px;
  margin-top: 10px;
}

#bulk-enrichment .mandatory-desc img {
  margin-top: 3px;
}

#bulk-enrichment .mandatory-desc h3 {
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.28px;
}
#bulk-enrichment .modal-container-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
