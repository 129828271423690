#integration .integration-top-container {
  display: flex;
  flex-direction: column;
}

#integration .integrations-header {
  width: 100%;
  padding-bottom: 10px;
}


#integration .cta-buttons {
  display: flex;
  gap: 40px;
  align-items: center;
  margin-top: 20px;
}

#integration .cta-buttons a:last-child {
  font-weight: 500;
  text-decoration: underline;
  color: #3b53d1;
}

