#faq-container {
  position: relative;
  background: #fff;
  padding: 20px;
  font-size: 14px;
}
#faq-container h3 {
  font-weight: 700;
  font-size: 25px;
  margin-top: 30px;
}
#faq-container .faq-head {
  width: 350px;
  margin: 0 auto;
  text-align: center;
}
#faq-container .faq-head h3 strong {
  color: #4f36e7;
}
#faq-container .faq-head p {
  margin-top: 20px;
  font-weight: normal;
  color: #3d476a;
}
#faq-container .faq {
  border: 1px solid #edeff5;
  padding: 40px;
  border-radius: 20px;
  width: 80%;
  margin: 40px auto;
}
#faq-container .faq .faq-title {
  font-size: 17px;
  font-weight: bold;
  margin-top: 35px;
}
#faq-container .faq p {
  margin-top: 10px;
  width: 80%;
}
#faq-container .faq .faq-item {
  margin-left: 10px;
}
#faq-container .faq .faq-top {
  padding-bottom: 40px;
  border-bottom: 1px solid #edeff5;
}
#faq-container .logos {
  text-align: center;
  margin: 50px 0;
  margin-bottom: 80px;
}
#faq-container .logos img {
  width: 80px;
  margin: 0 40px;
  filter: contrast(10%);
}
