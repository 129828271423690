#legacy-choice {
  width: 700px;
  position: relative;
  text-align: center;
}
#legacy-choice .close {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
#legacy-choice p {
  font-size: 14px;
  width: 80%;
  margin: 0 auto;
  margin-top: 5px;
}
#legacy-choice h1 {
  background: linear-gradient(97.22deg, #e66c6c 10.8%, #da47c4 95.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 1px 2px rgba(230, 108, 111, 0.12);
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
}
#legacy-choice h2 {
  font-size: 30px;
  font-weight: bold;
}
#legacy-choice .radio-group {
  display: flex;
  justify-content: center;
  padding: 15px;
  font-size: 13px;
}
#legacy-choice .radio-group label {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 7px 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  background: #fff;
  cursor: pointer;
}
#legacy-choice .radio-group label.active {
  color: #5369df;
  border-color: #5369df;
}
#legacy-choice .radio-group label input {
  margin-right: 8px;
  transform: scale(1.2);
  accent-color: #5369df;
}

#legacy-choice .plans-wp {
  display: flex;
  justify-content: center;
  text-align: left;
}
#legacy-choice .plans-wp .legacy-section-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#legacy-choice .plans-wp {
  padding: 15px 0;
  background: #f4f3f8;
  width: max-content;
  margin: 0 auto;
  border-right: 1px solid #d4d4d4;
  border-radius: 16px;
}
#legacy-choice .plans-wp .legacy-section-container .permissions.inactive img {
  filter: grayscale(100%) brightness(100%);
  opacity: 0.8;
}
#legacy-choice .plans-wp .legacy-section-container .permissions.inactive {
  color: #afb0ba;
}
#legacy-choice .plans-wp .legacy-section-container .permissions div {
  display: flex;
  align-items: flex-start;
}
#legacy-choice .plans-wp .legacy-section-container .permissions div img {
  margin-top: 4px;
}
#legacy-choice .plans-wp.no-choice {
  margin-top: 20px;
}
#legacy-choice .plans-wp h3 {
  font-size: 17px;
  font-weight: bold;
}
#legacy-choice .plans-wp > div {
  padding: 10px 20px;
  background: #f4f3f8;
  width: 300px;
}
#legacy-choice .plans-wp .permissions {
  margin-top: 20px;
  min-height: 200px;
}
/*#legacy-choice .plans-wp .permissions.inactive{
  filter: contrast(0);
  opacity: .8;
}*/

#legacy-choice .plans-wp .permissions div {
  display: flex;
  align-items: center;
  margin: 7px 0;
  font-size: 14px;
}
#legacy-choice .plans-wp .permissions div img {
  margin-right: 5px;
  margin-bottom: -2px;
  width: 15px;
}
#legacy-choice .plans-wp > div:first-child {
  border-right: 1px solid #d4d4d4;
  background: #f4f3f8;
}
#legacy-choice .plans-wp button {
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 15px;
}
#legacy-choice .plans-wp > div button.btn2 {
  background: #ffff;
  border: 1px solid #5369df;
  color: #5369df;
}
#legacy-choice a {
  margin: 20px 0;
  display: inline-flex;
  color: #5369df;
  text-decoration: underline;
}
#legacy-choice.legacy-confirm {
  width: 500px;
}
#legacy-choice.legacy-confirm .plans-wp,
#legacy-choice.legacy-confirm .plans-wp > div {
  background: transparent;
  border: none;
  width: 100%;
}
#legacy-choice.legacy-confirm .plans-wp button {
  margin: 10px;
}
#legacy-choice .legacy_currentPlan {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
#legacy-choice .legacy-price-container {
  display: flex;
  justify-content: space-between;
}

#legacy-choice .legacy-price-container .legacy-Price-line {
  display: inline-flex;
}
#legacy-choice .legacy-price-container > span:first-child {
}
#legacy-choice .legacy-price-container > span:last-child {
  font-size: 12px;
}
#legacy-choice .legacy-price-container > span:last-child strong {
  font-size: 19px;
}
#legacy-choice .legacy-price-container .billed-type {
  font-size: 12px;
  margin-top: 5px;
  font-weight: 400;
  height: 15px;

}
