#learn-about-addons.cta-button-container button {
  display: flex;
  align-items: center;
  padding: 8px 5px;
  margin-bottom: 16px;
  font-size: 13.7px;
}
#learn-about-addons.cta-button-container button.current:hover{
    background: #6366F110 !important;
    transition: all 0.2s ease;

}
