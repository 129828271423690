#connection-modal-right .list-item {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  padding-left: 15px;
}

#connection-modal-right .list-item p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
