.total-member-infos .n-members {
  border-radius: 6px;
  background-color: #f1edff;
  color: #573ad7;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  padding: 5px;
  margin-left: 5px;
  font-size: 13px;
}
.total-member-infos {
  position: relative;
}
.total-member-infos .n-invitations {
  border-radius: 6px;
  background-color: rgba(255, 196, 150, 0.66);
  color: #ca5f1a;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  padding: 5px;
  margin-left: 5px;
  font-size: 13px;
}
.features-infos {
  position: relative;
  margin-top: 30px;
}
.features-infos label,
.features-infos li {
  font-size: 13px;
}
.features-infos .features-listing li {
  width: 30% !important;
  display: inline-flex;
}
