#join-wk-no-domain-ctn {
  display: flex;
  position: absolute;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#join-wk-no-domain-ctn h1 {
  font-size: 32px;
  font-weight: 600;
  color: #0a0a0c;
  line-height: 37.5px;
  text-align: center;
  letter-spacing: 0.01rem;
}

#join-wk-no-domain-ctn p {
  font-size: 16px;
  font-weight: 400;
  color: #414143;
  margin-bottom: 35px;
  text-align: center;
}

#join-wk-no-domain-ctn .domain-input-field {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
}

#join-wk-no-domain-ctn .email-domain {
  font-size: 16px;
  font-weight: 600;
}

#join-wk-no-domain-ctn .btn1 {
  width: 520px;
  margin-top: 20px;
}

#join-wk-no-domain-ctn .btn1:disabled,
#join-wk-no-domain-ctn .btn1.disabled {
  color: #ffffff;
  background: #ccc;
  cursor: not-allowed;
  text-shadow: none;
}
