/* PreSignUp CSS */

#signup-form {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr;
  gap: 20px;
  min-height: 100vh;
}

#signup-form .signup-left {
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#signup-form .signup-left .form {
  width: 80%;
  max-width: 30vw;
}

#signup-form .signup-left .header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 2rem;
}

#signup-form .signup-left .header-wrapper h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.27px;
  color: #0d1829;
  text-align: center;
}

#signup-form .signup-left .header-wrapper h2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #797f99;
}

#signup-form .signup-left .terms-and-conditions,
#signup-form .signup-left .policy {
  color: #5369df;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
}

#signup-form .signup-left .terms-and-conditions:hover,
#signup-form .signup-left .policy:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
}

#signup-form .signup-left .terms-and-conditions:after,
#signup-form .signup-left .policy:after {
  content: none;
}

#signup-form .signup-left .checkemail-error {
  font-size: 14px;
  letter-spacing: -0.16px;
  line-height: 20px;
  color: #e23f74;
  margin-top: -10px;
  padding-bottom: 10px;
  padding-left: 0.5rem;
}

#signup-form .signup-left .terms-error {
  font-size: 14px;
  letter-spacing: -0.16px;
  line-height: 20px;
  color: #e23f74;
  margin-top: -16px;
  padding-bottom: 10px;
  padding-left: 2.5rem;
}

#signup-form .signup-left .separator-ctn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 1rem 0;
}

#signup-form .signup-left .separator-ctn .hr {
  width: 100%;
  height: 1px;
  background-color: #797f99;
}

#signup-form .signup-left .sso-btn {
  display: 'flex';
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 0px #5369df1f;
  box-shadow: 0px 1px 2px 0px #5369df1f;
  border: 1px solid #797f99;
  height: 52px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.20571428537368774px;
  color: #797f99;
  text-align: center;
}

#signup-form .signup-left .sso-btn .sso-centered {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#signup-form .signup-left .sso-btn img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

#signup-form .signup-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 80px;
  width: 100%;
  background: linear-gradient(153.13deg, #6e60f8 -7.58%, #ac54e5 59.65%, #d94cd7 108.33%);
}

#signup-form .signup-right .signup-right-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

#signup-form .signup-right .signup-right-top img {
  width: 60px;
}

#signup-form .signup-right .signup-right-top h2 {
  font-family: 'PT Serif';
  font-size: 34px;
  font-style: italic;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: 0.13px;
  text-align: center;
  color: #ffffff;
  text-wrap: balance;
}

#signup-form .signup-right .signup-right-user {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#signup-form .signup-right .signup-right-user img {
  width: 48px;
  height: 48px;
}

#signup-form .signup-right .signup-right-user .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

#signup-form .signup-right .signup-right-user .info p:first-child {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #ffffff;
}

#signup-form .signup-right .signup-right-user .info p:last-child {
  font-size: 10px;
  font-weight: 600;
  line-height: 12.91px;
  text-align: left;

  color: #ffffff;
}

#signup-form .signup-right .signup-right-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 20px;
}

#signup-form .signup-right .signup-right-bottom .heading-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

#signup-form .signup-right .signup-right-bottom h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  white-space: nowrap;
  padding-top: 7px;
}

/* 6 first elements */
#signup-form .signup-right .signup-right-bottom .integrations-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto); /* 2 lignes for the first 6 elements */
  gap: 10px;
  width: 100%;
}

/* 2 elements in the last row */
#signup-form .signup-right .signup-right-bottom .integrations-row {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 66.6%;
}

#signup-form .signup-right .signup-right-bottom .intg-wrapper {
  width: 100%;
}

#signup-form .signup-right .signup-right-bottom img {
  width: 100%;
  height: auto;
}

#auth .floating-effect {
  position: relative;
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
}
#auth div.verticalSlider {
  margin-bottom: 0;
}
#auth .steps-block {
  margin-bottom: 0;
}

#auth .signup-left {
  padding-top: 2rem !important;
}

#auth .signup-right .checkbox-wrap {
  margin-bottom: 10px !important;
}

/* PostSignUp CSS */

#post-signup-form {
  min-height: 100vh;
  display: flex;
  padding-top: 10vh;
  justify-content: center;
}

#post-signup-form .post-signup-container {
  height: 100%;
  width: 100%;
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#post-signup-form .post-signup-container .form {
  width: 100%;
  max-width: 35vw;
}

#post-signup-form .post-signup-container .header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5rem;
}

#post-signup-form .post-signup-container .header-wrapper h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.27px;
  color: #0d1829;
  text-align: center;
}

#post-signup-form .post-signup-container .header-wrapper h2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #797f99;
}

#post-signup-form .post-signup-container .disabled-btn {
  background-color: #b0b0b0 !important;
  pointer-events: none;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
}

#post-signup-form .post-signup-container .password-requirements {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 10px;
  margin: 10px 0px 40px 0px;
}

#post-signup-form .post-signup-container .password-requirements .pw-ctn {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 5px;
}

#post-signup-form .post-signup-container .condition-met {
  color: #12aa5b;
}

#post-signup-form .post-signup-container .condition-not-met {
  color: grey;
}

.google-error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
  width: 100%;
}

.sso-btn {
  margin-bottom: 8px;
}
