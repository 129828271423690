#field .toggle-password-visibility {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

#field .toggle-password-visibility img {
  width: 20px;
  height: 20px;
  filter: brightness(0) saturate(100%) invert(36%) sepia(0%) saturate(9%) hue-rotate(186deg)
    brightness(90%) contrast(92%);
}
