.table-content-overflow {
  position: relative;
  overflow-x: auto;
  overflow-y: visible;
  white-space: nowrap;
}
.first-pros-actions-container {
  margin-left: 60px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.first-pros-actions-container .actions-wrapper {
  margin-right: 59px;
}