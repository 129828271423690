#display-wk-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

#display-wk-info .display-wk-left {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 14px;
}

#display-wk-info .display-wk-left img {
  padding-bottom: 4px;
}

#display-wk-info .display-wk-left-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

#display-wk-info .display-wk-left-inner h1 {
  font-size: 20px;
  font-weight: 500;
  color: #242946;
  margin-bottom: 0;
}

#display-wk-info .display-wk-left-inner p {
  font-size: 14px;
  font-weight: 400;
  color: #595f79;
}
