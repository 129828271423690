#plan-card-old {
  position: relative;
  padding: 24px !important;
  transition: transform 0.3s ease;
  z-index: 1;
  box-shadow: 0 0 8px 0 #0000001a;
  border-radius : 16px;
  width:25%
}

#plan-card-old:hover {
  transform: scale(1.02);
  cursor: pointer;
  z-index: 2;
}

#plan-card-old .plan-card-tag {
  position: absolute;
  top: -15px;
  right: 15px;
  padding: 2px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-align: left;
  border: 1px solid #e0e1e7;
  background-color: #ffffff;
  color: #797f99;
  text-transform: uppercase;
}

#plan-card-old .permissions {
  margin-bottom: 0 !important;
}

#plan-card-old .permissions .permission-line img {
  width: 22px;
  height: 22px;
}
#plan-card-old .permissions .permission-name {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #495377;
}
#plan-card-old .permissions .permission-name img {
  margin-right: -15px;
  margin-left: 5px;
  width: 20px;
  margin-top: 2px;
}
#plan-card-old .unlimited-b2b {
  display: flex;
  align-items: center;
  gap: 5px;
  background: #ffe9b3;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  width: max-content;
  color: #495377;
}
#plan-card-old .unlimited-b2b img {
  margin-top: -3px;
  width: 13px;
}
#plan_tab .select-credits {
  font-size: 14px;
  padding: 0 0 24px 0;
  width: 100%;
}
#plan-card-old .select-credits p {
  margin: 7px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #495377;
}
#plan-card-old .select-credits p span {
  color: #0a66c2;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  margin-left: 10px;
  border: 1px solid #ccc;
  font-size: 17px;
  cursor: pointer;
}
#plan-card-old .view-more-details {
  color: #555;
  position: absolute;
  bottom: 20px;
  text-decoration: underline;
}
#plan-card-old .view-more-details img {
  transform: rotate(90deg);
  margin-left: 5px;
  margin-top: -3px;
}
#plan-card-old .deactivate-plan {
  opacity: 1;
  /* pointer-events: none; */
  transition: all 0.5s ease;
}
#plan-card-old .activate-plan {
  opacity: 1;
  transition: all 0.2s ease;
}
#plan-card-old .billed-annually {
  position: relative;
  top: -10px;
  color: #495377;
}

#plan-permissions .plan-permissions-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

#plan-permissions h2 {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #111833;
  margin-bottom: 20px;
}

#plan-permissions h3 {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #111833;
}

#plan-permissions ul li {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #495377;
}

#plan-card-old .credits-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 24px;
  width: 100%;
}

#plan-card-old .credits-section .credits-section-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#plan-card-old .credits-section-title h3 {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #111833;
}

#plan-card-old .credits-section-title img {
  width: 13.33px;
  height: 13.33px;
}

#plan-permissions .credits-section-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#plan-permissions .credits-section-title h3 {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #111833;
}

#plan-permissions .credits-section-title img {
  width: 13.33px;
  height: 13.33px;
}

#plan-card-old .plan-card-title {
  align-items: start;
  width: 100%;
  font-size: 28px;
  font-weight: 600;
  color: #111833;
}

#plan-card-old .discount_tag {
  width: 100px;
  padding: 6px 10px;
  border-radius: 8px;
  background: linear-gradient(90deg, #0eb763 0%, #1c8d9c 100%);
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
}

#plan-card-old .text-price {
  margin-top: 10px;
}

#plan-card-old .text-price .font-sm {
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: #111833;
}

#plan-card-old .text-price .price {
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  color: #111833;
}

#plan-card-old .text-price .price-subtitle {
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  color: #979797;
}

#static-plan span {
  font-size: 14px;
  font-weight: 500;
  color: #979797;
}

.plan-card-selected-old {
  background-image: linear-gradient(180deg, #fff8f8 0%, #ffffff 48.44%) !important;
  border: none;
}

.plan-card-default {
  background-color: #fff !important;
}

#plan-card-old .christmas-reduction-tag {
  position: absolute;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
  font-size: 28px;
  font-weight: 500;
  color: #e26285;
}

#plan-card-old .linethrough-value {
  color: #a5a9bb;
  text-decoration: line-through;
  font-weight: 400;
}
