.about-addons-popup {
  border-radius: 100px;
  color: #33374d;
}
.about-addons-popup__title {
  font-size: 20px;
}
.about-addons-popup__text {
  font-size: 15px;
  margin: 20px 0;
}
.about-addons-popup__switch {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}
.about-addons-popup__switch li a {
  padding: 0 !important;
  min-width: 80px !important;
  font-size: 15px !important;
}

.about-addons-popup__switch li a.active {
  color: black !important;
}

.about-addons-popup__tabs {
  width: 100%;
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
}
.about-addons-popup__tabs > span {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.about-addons-popup__tabs > span::first-letter {
  text-transform: uppercase;
}
.about-addons-popup__tabs .active {
  border-bottom: 2px solid #4d68f8;
}

.about-addons-popup__table {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}
.about-addons-popup__table thead tr {
  color: #757ca3;
}
.about-addons-popup__table tr:not(.about-addons-popup__table tr:last-child) {
  border-bottom: 1px solid #e3e7fa;
}
.about-addons-popup__table tr td {
  padding: 10px;
  width: calc(100% / 3);
}
