#files .files-header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

#files .files-header-right {
  display: flex;
}

#files .files-header-container h1 {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.12px;
  color: #0d1021;
  padding-top: 10px;
}

#files .pagination-label {
  margin: 10px 12px 0px 12px;
  color: #595f79;
}

#files .pagination-container {
  color: #595f79;
}
