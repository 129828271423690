
#right_modal {
    position: fixed;
    top: 0;
    bottom:0;
    right: 0;
    left:0;
    z-index: 10000;
    background: rgba(0,0,0,.2);
}
#right_modal .head-right-modal {
    position: fixed;
    background: #fff;
    top: 0;
    right: -602px;
    width: 600px;
    bottom: 0;
    padding: 10px;
    box-shadow: -1px 0px 10px rgba(0,0,0,.2);
    transition: all .2s ease;

}
#right_modal.show .head-right-modal{
    transition: all .2s ease;
    right: 0;
}
