#order-summary-ctn {
  width: 50%;
  height: 100%;
  min-height: 100vh;

  background-color: #f4f3f9;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: start;
  padding-top: 4vh;
  padding-right: 3vw;
}

@media (min-width: 1536px) {
  #order-summary-ctn {
    padding-top: 10vh;
  }
}

#order-summary-ctn .order-summary-wrapper {
  width: 90%;
  max-width: 400px;
}

#order-summary-ctn .order-summary-wrapper .plan-title-ctn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#order-summary-ctn .order-summary-wrapper .plan-title {
  color: #6e6e6e;
  font-weight: 500;
  font-size: 1rem;
  width: 100%;
}

#order-summary-ctn .total-price-title {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 2rem;
  width: 100%;
}

#order-summary-ctn .total-price-title .total-price-inner {
  width: 90%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

#order-summary-ctn .total-price-title .total-price-inner span {
  font-size: 2rem;
  font-weight: 600;
}

#order-summary-ctn .total-price-title .total-price-inner p {
  width: 0.1rem;
  line-height: 100%;
  color: #6e6e6e;
  font-weight: 500;
  font-size: 1rem;
}

#subtotal-ctn {
  display: flex;
  justify-content: center;
}

#subtotal-ctn .subtotal-wrapper {
  width: 100%;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding-top: 1.5rem;
  border-top: 1px solid #dcdcdc;
}

#subtotal-ctn .subtotal-wrapper p {
  font-weight: 600;
}

#due-today-section {
  display: flex;
  justify-content: center;
}

#due-today-section .due-today-wrapper {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding-top: 1.5rem;
  border-top: 1px solid #dcdcdc;
}

/* HEADER */

#order-summary-ctn .btn-wrapper {
  position: relative;
  padding-top: 0.5rem;
  width: 100%;
  height: 4rem;
}

#order-summary-ctn .back-home-btn {
  display: flex;
  align-items: center;
  position: relative;
}

#order-summary-ctn .logo-image {
  width: 100px;
  transition: opacity 0.3s ease;
  position: absolute;
  top: -5px;
}

#order-summary-ctn .back-text {
  position: absolute;
  left: 6.5rem;
  top: 0.75rem;
  transform: translate(-50%, -50%);
  opacity: 0;
  white-space: nowrap;
  transition: opacity 0.3s ease;
}

#order-summary-ctn .back-home-btn:hover .logo-image {
  opacity: 0;
}

#order-summary-ctn .back-home-btn:hover .back-text {
  opacity: 1;
}
