#move-wk-modal .move-wk-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0 20px 20px 20px;
}

#move-wk-modal .move-wk-footer a {
    padding: 0 30px;
}
