.icon-style {
  margin-top: 10px;
  cursor: pointer;
  color: #797f99;
}

.icon-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0 2rem 0;
}

.toast-container {
  top: 100px;
}

.modal-header {
  border: 0;
}

.modal-footer {
  border: 0;
}

#api-key-ctn {
  margin-bottom: 0;
}
