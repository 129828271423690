.workspace-billing-info {
  display: flex;
  margin-bottom: 30px;
}
.workspace-billing-info section {
  margin-right: 30px;
  width: 250px;
  padding-left: 30px;
}
.workspace-billing-info section div {
  color: #797f99;
  margin-bottom: 10px;
}
.workspace-billing-info section p {
  font-weight: bold;
  color: #555;
}
#statistics_tab .info-card-header p {
  font-size: 17px;
}
#organization body tr td {
  text-align: left !important;
}
/*#statistics_tab .credits-history .custom-select-picker {
    height: 40px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.84);
    box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
    display: flex;
    align-self: center;
    vertical-align: middle;
    align-items: center;
    line-height: 38px;
    text-align: left;
    position: relative;
  }*/
#organization .custom-select-picker .member-select-input {
  padding: 5px 0;
}
#statistics_tab .usage-by-member .info-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  border: none;
}

#statistics_tab .usage-by-member .info-card-header .page-header {
  display: flex;
  align-items: center;
}
#statistics_tab .usage-by-member .info-card-header .page-header > div {
  margin-left: 20px;
}
#statistics_tab
  .usage-by-member
  .info-card-header
  .page-header
  .search-ui
  input {
  box-shadow: 0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  width: 200px;
}
#statistics_tab .usage-by-member .coin-usage {
  display: flex;
}

#organization .pagination-inline {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  margin-left: 20px;
}

#organization .pagination-inline label {
  margin: 0;
  color: #666;
  font-size: 13px !important;
}

#organization .credits-history .custom-select-picker .member-select-input,
#organization .add-on.dropdown-toggle,
#organization .drop-down-cstm span {
  font-size: 14px;
}
#organization .add-on.dropdown-toggle {
  width: 150px;
}
#organization .add-on.dropdown-toggle span:after,
#organization .drop-down-cstm span:after {
  display: none;
}
#organization .drop-down-cstm.cstm-select-checkboxes {
  display: block;
  padding: 10px 15px;
  max-width: 310px;
}
#organization .drop-down-cstm.cstm-select-checkboxes label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 0px;
  margin: 0;
  min-width: 140px;
}

#organization .drop-down-cstm label:hover {
  color: #000;
}
#organization .drop-down-cstm.cstm-select-checkboxes label span {
  padding: 5px;
}
#organization .drop-down-cstm.cstm-select-checkboxes label input {
  cursor: pointer;
  accent-color: #3b53d1;
  transform: scale(1.1);
}

#organization .drop-down-cstm {
  /* box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12),
       0 2px 4px 0 rgba(20, 24, 47, 0.1), 0 4px 8px 0 rgba(20, 24, 47, 0.1),
       0 12px 16px 0 rgba(20, 24, 47, 0.12), 0 16px 32px 0 rgba(20, 24, 47, 0.24);*/
  box-shadow: 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1), 0 4px 8px 0 rgba(20, 24, 47, 0.1);
  background: white;
  padding: 10px 0;
  height: max-content;
  border-radius: 6px;
  width: max-content;
  position: absolute;
  top: 50px;
  left: 0px;
}
.cstm-select-hover {
  width: 160px;
}
.cstm-select-hover > span {
  color: #595f79;
}
.cstm-select-hover > span:hover {
  /* background: #3b53d1;
  color: #fff !important;*/
  color: #000;
}
#organization .workspaces-container {
  padding-top: 20px;
}

#organization .workspaces-container h2 {
  font-size: 17px;
  margin-bottom: 20px;
}
#organization .workspaces-container #ColleaguesCard {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px;
}
#organization .workspaces-container #ColleaguesCard .membersAvatarContainer {
  margin-right: 20px;
}

.add-member-upgrade {
  padding: 16px;
  background: #f5f5f5;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;

  color: rgba(255, 113, 73, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-member-upgrade button {
  background: #fff;
  color: rgba(252, 39, 128, 1);
}
.add-member-upgrade button:hover {
  background: #fff;
  color: rgba(252, 39, 128, 1);
}
.add-member-licences {
  font-size: 14px;
  margin-top: 10px;
  display: flex;
}
.add-member-licences img {
  margin-right: 10px;
}