#salesautomation-settings .workflow-main-container {
  background-color: #ffffff !important;
  height: auto;
  width: 100%;
  padding: 24px;
  box-shadow: 0 4px 12px 0 #24294629;
  border-radius: 10px;
}

#salesautomationcontent .workflow-input-field {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

#salesautomationcontent .workflow-input-field h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.145px;
  text-align: left;
  color: #797f99;
}
#salesautomation-settings .workflow-main-container h1 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #0d1021;
  letter-spacing: -0.12px;
  text-align: left;
}

#salesautomationcontent .owner-container {
  margin-bottom: 10px;
}

#wk-name-input {
  height: 40px;
  border: 1px solid #14182f1a;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 #14182f1a;
  padding: 10px;
  width: 50%;
}

#salesautomationcontent .owner {
  height: 40px;
  border: 1px solid #14182f1a;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 #14182f1a;
  padding: 10px;
  width: 50%;
}

#salesautomationcontent .workflow-input-field ul {
  display: flex;
  gap: 10px;
}

#salesautomationcontent .salesautomation-integrations {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
