.item-container td {
  text-align: center;
}

.item-container td:first-child {
  max-width: 200px;
}

.item-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 0.16px;
  color: #242946;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.actions-icons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 5px;
}

.icon {
  width: 18px;
}

.icon:hover {
  filter: contrast(50%);
}

.status-ok {
  color: #4caf50 !important;
}

.status-failed {
  color: #ff5733 !important;
}

.status-processing {
  color: #007bff !important;
}

.status-pending {
  color: #ffc107 !important;
}

.delete-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 35px 30px 10px 20px;
}

.delete-popup p {
  font-size: 17px;
  font-weight: 400;
  color: #797f99;
}

.delete-popup-btn {
  display: flex;
  justify-content: center;
  gap: 30px;
}
