#workflow-delete-container {
    margin-top: 20px;
    background-color: #ffffff !important;
    height: auto;
    width: 100%;
    padding: 24px;
    box-shadow: 0 4px 12px 0 #24294629;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

#workflow-delete-container h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.12px;
    text-align: left;
    color: #0d1021;
}

#workflow-delete-container p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.24px;
    text-align: left;
    color: #595f79;
}

#workflow-delete-container .delete-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    text-decoration: underline;
    color: #d71717;
    padding-left: 10px;
}
