.optout-container {
  border: 1px solid #ccc;
  background: white;
  width: 60%;
  margin: 0 auto;
  text-align: center;
  margin-top: 70px;
  padding: 30px;
}
.optout-container h1 {
  font-size: 20px;
}
.optout-container h1 {
  font-size: 17px;
}
.optout-container p {
  font-size: 13px;
  color: #555;
}
.optout-container .field-container {
  margin-top: 20px;
}
.optout-container form {
  margin: 20px 0;
  margin-bottom: 40px;
}
.optout-container .field-container input {
  width: 50%;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 2px;
}
.optout-container button {
  background: #5369df;
  color: #fff;
  width: 50%;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 2px;
  margin: 40px 0;
}

.optout-container .field-container label {
  margin: 0;
  width: 50%;
  text-align: left;
  font-size: 12px;
  color: #555;
}
.optout-container footer p {
  font-size: 11px;
  color: #666;
  width: 50%;
  margin: 0 auto;
}
.optout-container #recaptcha {
  display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    margin-bottom: 0;

}
.optout-success{
    margin: 150px auto;
}
.info-text {
  text-align: center;
  font-style: italic;
}
.info-text span {
  color: #0a66c2;
}