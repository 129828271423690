

#custom-toolTip-container {
  position: relative;
  display: inline-block;
}

.custom-toolTip {
  background: #18223a;
  color: white;
  padding: 10px;
  border-radius: 7px;
  white-space: nowrap;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 13px;

}

.custom-toolTip-index {
  position: absolute;
  width: 7px;
  height: 7px;
  background: #18223a;
  transform: rotate(-45deg);
}

.custom-toolTip.top .custom-toolTip-index {
  bottom: -4px;
  left: 52%;
  transform: translateX(-50%) rotate(-45deg);
}

.custom-toolTip.bottom .custom-toolTip-index {
  top: -4px;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
}

.custom-toolTip.left .custom-toolTip-index {
  right: -4px;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.custom-toolTip.right .custom-toolTip-index {
  left: -4px;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.onlyTooltip {
  padding: 5px !important;
  font-size: 14px !important;
}
