#customize-plan-page-client {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 20px;
  background-color: #fff;
  display: flex;
  gap: 20px;
  margin-bottom: 60px;
}

#customize-plan-page-client .left-container h1 {
  font-size: 24px;
  font-weight: 600;
  color: #0d1021;
  margin-bottom: 20px;
}

#customize-plan-page-client .left-container {
  width: 55%;
  height: 100%;
}

#customize-plan-page-client .right-container {
  width: 45%;
  height: 100%;
}

#customize-plan-page-client .summary {
  padding: 20px;
  border-radius: 12px;
  background-color: #f5f5f9;
}
