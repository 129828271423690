#notes_tab .form-group textarea {
  width: 100%;
  border-radius: 5px;
  border-color: #999;
  height: 150px;
  padding: 10px;
  outline: none;
}
#notes_tab .notes-container {
  /*   max-height: 400px;
    padding: 20px 10px;
    overflow: auto;*/
}
#notes_tab .add-note-button-container {
  display: flex;
  justify-content: flex-end;
}
#notes_tab .add-note-button-container button {
  margin-left: 20px;
  margin-top: 10px;
}
#notes_tab .note-dropdown {
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}
#notes_tab .note-dropdown textarea::placeholder {
  color: #ccc;
}
#notes_tab .addnote-dropdown-anim {
  height: 210px;
}
