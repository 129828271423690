.status-bar-container {
  width: 100%;
  margin-bottom: 5px;
}

.status-bar {
  background-color: #cdcdcd;
  overflow: hidden;
  height: 8px;
}

.status-bar__fill {
  background-color: #d64dd8;
  height: 100%;
  width: 0;
  transition: width 0.2s ease-in-out;
}
