#workspaces-avatars .workspaces-avatars-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 40px;
}

#workspaces-avatars .workspaces-avatars-header h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 37.5px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #0a0a0c;
}

#workspaces-avatars .workspaces-avatars-header p {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: center;

  color: #414143;
}

#workspaces-avatars .wk-ctn {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #14182f1a;
  padding: 20px 20px 10px 20px;
  width: 100%;
  max-width: 40rem;
}

#workspaces-avatars .wk-ctn .top-wk-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  background-color: #f6f4fa;
  border-radius: 10px;
  padding: 20px;
}

#workspaces-avatars .wk-ctn h2 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #0a0a0c;
}

#workspaces-avatars .wk-ctn p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: #414766;
}

#workspaces-avatars .btn1 {
  width: 100%;
  margin-top: 10px;
}

#workspaces-avatars .bottom-wk-ctn {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

#workspaces-avatars .bottom-wk-ctn .btn1,
#workspaces-avatars .bottom-wk-ctn .btn2 {
  width: 100%;
}

#workspaces-avatars .bottom-wk-ctn .btn-link {
  color: #495eda;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
}

#workspaces-avatars .btn-link:hover {
  text-decoration: underline;
}

#workspaces-avatars .display-workspaces {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: start;
}

#workspaces-avatars .show-all-workspaces {
  margin-top: 10px;
}

#workspaces-avatars .show-all-workspaces h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #595f79;
}

#workspaces-avatars .no-match {
  display: flex;
  margin: 20px 0;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

#non-existing-dashboard {
  position: relative;
  min-width: 100%;
  height: 100%;
}
