.notifications-settings .body-content{
    display: flex;
    flex-direction: row;

}
.notifications-settings .inner-body-content {
    padding-bottom:0px;
}

.notifications-settings .content-box-ui {
    padding-bottom: 20px;
}
.notifications-settings .inner-body-content  .notification-category {
    border : 1px solid #e5e5e5;
    border-radius: 5px;
    display: inline-block;
    padding: 15px;
    padding-right: 30px;
    margin-right: 30px;
    min-width: 480px;
    margin-bottom:20px

}
.notifications-settings .inner-body-content   .settings-title {
    color: #333;
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 20px;
}
.notifications-settings .inner-body-content   .settings-title span {
    color: #595f79;
    font-size: 14px;
    letter-spacing: 0.24px;
    line-height: 20px;
    font-weight: normal;
    margin-bottom: 5px;
    margin-top: 8px;
}
.notifications-settings .inner-body-content .notification-category .notif-settings-container .checkbox-wrap {
    margin: 10px ;
    cursor: pointer;
    /*
    display: block;
    */
}

.notifications-settings .inner-body-content .notification-category  .checkbox-wrap  .checkbox-icon {
    margin-right: 27px;
}
.onboarding-settings {
    padding: 20px;
}
.onboarding-settings .onBoarding-settings-item {
    display: flex;
    justify-content: space-between;
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    margin-bottom: 20px;
    padding:20px;
    cursor: pointer;

}
.onboarding-settings .onBoarding-settings-item:hover {
    box-shadow: 5px 5px 5px rgba(50,50,50,.1);
}
.onboarding-settings .onBoarding-settings-item .onBoarding-settings-item-wrapper {
    display: flex;
}
.onboarding-settings .onBoarding-settings-item .onBoarding-item-content {
    margin-left: 15px;
}
.onboarding-settings .onBoarding-settings-item .onBoarding-item-content h3{
    font-size: 16px;
    margin-bottom: 2px;
    color: #495377;
}
.onboarding-settings .onBoarding-settings-item .credits-wrp{
    display: flex;
    align-items: center;
}
.onboarding-settings .onBoarding-settings-item .plus {
    color: #495377;
    background: #ebebeb;
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 3px;
    font-size: 12px;
}
.onboarding-settings .onBoarding-settings-item .credits-wrp {
    font-size: 14px;
}
.onboarding-settings .validated {
    display: flex;
    padding: 10px;
    border: 1px solid #ececec;
    border-radius: 8px;
    margin-bottom: 20px;
    cursor: pointer;
    background: darkslategrey;
    pointer-events: none;
}
.onboarding-settings .onBoarding-settings-item.validated {
    display: flex;
    padding: 10px;
    border: 1px solid #ececec;
    border-radius: 8px;
    margin-bottom: 20px;
    cursor: pointer;
    background: #F0F0F0;
    pointer-events: none;
}

.onboarding-settings .onBoarding-settings-item.validated * {
    opacity: .9;
}
.onboarding-settings progress {
    -webkit-appearance: none;
    width: 100%;
}
.onboarding-settings progress::-webkit-progress-bar {
    /* style rules */
    background: #ddd;
    border-radius: 5px;
    height: 10px;
}
.onboarding-settings progress::-webkit-progress-value {
    border-radius: 5px;

    background: #595f79;
}
.onboarding-settings  .with-progress {
justify-content: space-between;
    margin-bottom: 30px;
    padding-left: 0!important;
    padding-top: 0!important;
}
.onboarding-settings  .with-progress  .progress-container {
    display: flex;
    width: 350px;
    align-items: center;
}
.onboarding-settings  .with-progress .progress-container p {
    white-space: nowrap;
    margin-left: 14px;
    color: #595F79;
    font-size: 14px;
}
