#discount-code-ctn {
  display: flex;
  justify-content: end;
  margin-bottom: 1.5rem;
  width: 100%;
  cursor: pointer;
}

#discount-code-ctn .discount-code-wrapper {
  padding-top: 1rem;
  width: 100%;
}

#discount-code-ctn .discount-code-wrapper .discount-btn {
  width: 100%;
  color: #5369df;
  font-weight: 500;
  cursor: pointer;
}

#discount-code-ctn .input-btn {
  transition: all 0.3 ease;
  position: relative;
}

#discount-code-ctn .input-btn input {
  padding: 0.5rem 1rem;
  width: 100%;
  border-radius: 10px;
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);

  border: 1px solid #d5d7e4;
}

#discount-code-ctn .input-btn .apply-btn {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  color: #5369df;
  font-weight: 500;
  cursor: pointer;
}

.coupon-ctn {
  background-color: #ffffff;
  padding: 0.25rem 2.5rem 0.25rem 0.5rem;
  position: relative;
  border: 1px solid #d5d7e4;
}

.coupon-percent-off {
  position: absolute;
  bottom: -1.25rem;
  color: #696969;
  font-size: 12px;
  white-space: nowrap;
  z-index: 20;
}

.cancel-coupon-btn {
  position: absolute;
  right: 0.5rem;
  cursor: pointer;
}
