#customize-summary .summary-top-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: start;
}

#customize-summary .summary-top-container .top-container-price-info {
  display: flex;
  align-items: center;
}

#customize-summary .summary-top-container .price-info-container {
  display: flex;
  align-items: end;
  flex-direction: column;

  height: 100%;
  width: 100%;
}

#customize-summary .summary-top-container .summary {
  font-size: 24px;
  font-weight: 600;
  color: #495377;
  padding: 0 !important;
  margin-top: 5px;
}

#customize-summary .summary-top-container .top-container-price-info .price {
  font-size: 42px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: left;
  color: #111833;
}

#customize-summary .summary-top-container .top-container-desc {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #e66d6d;
  text-transform: capitalize;
}

#customize-summary .summary-bottom-container {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 16px;
  margin-top: 14px;
}

#customize-summary .plan-ctn-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#customize-summary .plan-ctn-top .plan-price-only {
  font-size: 16px;
  font-weight: 500;
  color: #0d1021;
}

#customize-summary .summary-bottom-container .plan-ctn {
  display: flex;
  align-items: center;
  gap: 10px;
}

#customize-summary .summary-bottom-container .plan-ctn .plan-badge {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  border: 1px solid #e0e1e7;
  padding: 2px 8px;
  border-radius: 8px;
  color: #797f99;
  text-transform: uppercase;
}

#customize-summary .summary-bottom-container .plan-ctn .plan-type {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #e66d6d;
}

#customize-summary .summary-bottom-container .plan-ctn .plan-dropdown button {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #4d68f8;
  pointer-events: none;
}

#customize-summary
  .summary-bottom-container
  .plan-ctn
  .plan-dropdown
  .plan-inner-dropdown {
  cursor: pointer;
  width: 100%;
}

#customize-summary
  .summary-bottom-container
  .plan-ctn
  .plan-dropdown
  .plan-inner-dropdown
  li {
  padding: 2px 5px;
}

#customize-summary
  .summary-bottom-container
  .plan-ctn
  .plan-dropdown
  .plan-inner-dropdown
  li:hover {
  background: linear-gradient(96.13deg, #e66d6d -17.49%, #db48c4 150.79%);
  color: #fff;
  width: 100%;
  overflow: hidden;
}

#customize-summary
  .summary-bottom-container
  .plan-ctn
  .plan-dropdown
  .plan-inner-dropdown
  li:first-child:hover {
  background: linear-gradient(96.13deg, #e66d6d -17.49%, #db48c4 150.79%);
  color: #fff;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 10px;
}

#customize-summary .plan-checkout {
  margin-top: 20px;
  padding: 10px;
  background: linear-gradient(140.68deg, #5369df 4.7%, #4458c9 93.43%);
  box-shadow: 0px 2px 4px 0px #3b53d129;
  box-shadow: 0px 1px 2px 0px #3b53d124;
  font-size: 18px;
  font-weight: 500;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  color: #ffffff;
}

#customize-summary .plan-billing-cycle {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0;
}

#customize-summary .plan-billing-cycle .billing-period {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #72757e;
}

#customize-summary .plan-billing-cycle .cycle {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: #414766;
}

#customize-summary hr {
  border-top: 1px solid #d5d7e4;
  border-radius: 10px;
}

#customize-summary h2 {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #0d1021;
}

#customize-summary .credits-global-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  width: 100%;
}

#customize-summary .credits-global-info span {
  padding: 6px 12px;
  border-radius: 24px;
  background: #97979712;
  white-space: nowrap;
  display: inline-block;
  width: auto;
  box-sizing: border-box;
}

#customize-summary .plan-dropdown img {
  margin: 0 5px 3px 5px;
}

#customize-summary .summary-addon-section {
  display: flex;
  flex-direction: column;
}

#customize-summary .unlimited-b2b {
  display: flex;
  align-items: center;
  gap: 5px;
  background: #ffe9b3;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 1rem;
  width: max-content;
}
