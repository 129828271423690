
.editable-field .edit_profile_field {
    display: none;
    margin-right: 0;
    position: absolute;
    top: -7px;
}

.editable-field .edit_profile_field_container img {
    width: 18px;
    margin-top: -5px;
    padding: 2px;
}
.editable-field .edit_profile_field_container {
    width: 100%;
    border: none;
    border-bottom: 1px solid transparent;
    height: 100%;
    padding: 0;
    margin: 0;
    font-size: 14px;
}
.editable-field .edit_profile_field_container:hover {
    border-bottom: 1px solid #ccc;
}
 .editable-field:hover .edit_profile_field {
    display: inline-block;
    right: -12px;
     top:-20px;
     background: #fff;
     border: 1px solid #eee;
     padding: 1px 3px;
     border-radius: 2px;
     cursor: pointer;
}
.editable-field:hover .edit_profile_field img:hover{
    filter : contrast(0.5);

}
 .editable-field > span {
    width: 100% !important;
    display: inline;
}
.editable-field input {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 250, 0.4);
    height: 100%;
    font-size: 14px;
    background: transparent;
    width: 100%;
    padding: 0;
    margin: 0;
    display: block;

    padding-bottom: 3px;
}