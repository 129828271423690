.all-lists-ctn {
  position: relative;
}

.all-lists {
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px #14182f1f;
  box-shadow: 0px 0px 2px 0px #14182f1f;
  margin-top: 1rem;
  overflow: visible;
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom: 2rem;
}

.all-lists table {
  width: 100%;
  background-color: #ffffff;

  overflow: visible;
  table-layout: fixed;
  border-collapse: collapse;
}

.all-lists__head {
  padding: 0.5rem 1rem;
  color: #797f99;
  background-color: #f5f5f9;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.45px;
  text-align: left;
}

.all-lists__head__thead-first,
.all-lists__body__td-first {
  width: 40%;
  padding-left: 1rem;
}

.all-lists__head__thead {
  padding: 1rem 0;
  width: 15%;
  text-align: center;
}

.all-lists__body {
  color: #33374d;
}

.all-lists__body__td {
  text-decoration: none;
  padding: 1rem 0;
  border-top: 1px solid #f5f5f9;
  border-bottom: 1px solid #f5f5f9;
  text-align: center;
}

.all-lists__body__td-first {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.16px;
  text-decoration: none;
  cursor: pointer;
  padding-left: 1rem;
  text-align: left;
}

.all-lists__body__td-first:hover {
  text-decoration: underline;
}

.all-lists__body__td__action-btn {
  cursor: pointer;
  padding: 0.5rem;
  text-align: center;
}

.all-lists__body__td__action-btn ul {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 0.5rem 0;
  align-items: center;
}

.all-lists__body__td__action-btn ul li span {
  margin-right: 0.5rem;
}

.all-lists__body__trow {
  transition:
    box-shadow 0.3s ease,
    transform 0.3s ease;
}

.all-lists__body__trow:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.all-list-pagination {
  padding: 0.5rem 1rem 1rem 1rem;
  width: 100%;
  display: flex;
  justify-content: end;
}
