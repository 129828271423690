.date-picker-container {
  position: absolute;
  width: 300px;
  z-index: 10000;
  right: 0 ;
  bottom: -20px;
}
.date-picker-container .date-picker {
  box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1), 0 4px 8px 0 rgba(20, 24, 47, 0.1);
  border: none !important;
  border-radius: 20px;
  width: 100% !important;
  position: absolute !important;
  right: -10px !important;
  top: 0 !important;
  padding: 10px;
  transform: scale(0.95);
}

.export-btn .selected-label {
  border-radius: 10px;
  background: -webkit-linear-gradient(309.32deg, #5369df 0%, #4458c9 100%);
  background: -o-linear-gradient(309.32deg, #5369df 0%, #4458c9 100%);
  background: linear-gradient(140.68deg, #5369df 0%, #4458c9 100%);
  -webkit-box-shadow: 0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16);
  box-shadow: 0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16);
  height: 40px;
  border: none;
  color: #fff !important;
  padding: 0 14px;
  line-height: 40px;
  font-size: 16px;
  letter-spacing: 0.24px;
  text-shadow: -1px 1px 0 0 #3b53d1;
  font-weight: 500;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  text-align: center;
  margin: 0;
}

/* LEADS INFOS */
.profile-side-modal .modal.right.show {
    right: 0;
}

.profile-side-modal .modal.right.show .modal-dialog {
    right: 0;
    width: 530px;
    max-width: max-content;
}

.profile-side-modal .modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 494px;
    max-width: 494px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
    right: -692px;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.profile-side-modal .modal.right .modal-content {
    height: 100%;
    border-radius: 0;
    border: none;
    background: #ffffff;
    -webkit-box-shadow: -20px 4px 32px rgba(36, 41, 70, 0.04);
    box-shadow: -20px 4px 32px rgba(36, 41, 70, 0.04);
    position: relative;
}
.profile-side-modal .modal.right .modal-content .inner-content {
    margin-top: 220px;
}
.profile-side-modal .modal.right .modal-header,
.profile-side-modal .modal.right .modal-body {
    padding: 32px;
    overflow-y: auto;
}
.profile-side-modal .modal.right .modal-body {
    margin-bottom: 70px;
}
.profile-side-modal .modal .modal-header .close {
    height: 40px;
    width: 40px;
    border-radius: 6px;
    background-color: #f5f5f9;
    line-height: 40px;
    padding: 0 5px;
    text-align: center;
    font-weight: normal;
    font-size: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    margin: 0;
    opacity: 1;
    color: #797f99;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
}

.profile-side-modal .modal .modal-header .close:hover {
    opacity: 1;
    color: #454545;
}

.profile-side-modal .modal .modal-header .close img {
    position: relative;
    top: -2px;
}

.profile-side-modal .profile-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin-bottom: 0px;
    z-index: 100;
    top: 61px;
    background: #fff;
    padding-bottom: 30px;
}
.profile-side-modal .fixed {
    position: fixed;

}
.profile-side-modal .profile-section .profile-img-block {
    width: 84px;
    height: 84px;
    border-radius: 100%;
}

.profile-side-modal .profile-section .profile-img-block img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.profile-side-modal .profile-section .profile-info {
    width: calc(100% - 84px);
    padding-left: 16px;
}

.profile-side-modal .profile-section .profile-info .username {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #14182f;
    margin-bottom: 4px;
}

.profile-side-modal .profile-section .profile-info .designation {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.14px;
    color: #595f79;
    margin-bottom: 12px;
    vertical-align: top;
}

.profile-side-modal .profile-section .profile-info .social-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
}

.profile-side-modal .profile-section .profile-info .social-list li {
    margin-right: 9px;
}

.profile-side-modal .profile-section .profile-info .social-list li a {
    height: 27px;
    width: 27px;
    text-align: center;
    border: 1px solid #d5d7e4;
    border-radius: 4px;
    display: block;
    background: #fff;
    outline: none;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    position: relative;
}

.profile-side-modal .profile-section .profile-info .social-list li a:hover {
    border-color: #999ba7;
}

.profile-side-modal .profile-section .profile-info .social-list li a .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.profile-side-modal
.profile-section
.profile-info
.social-list
li
a.lnkdn-icon.active {
    background: #006699;
    border-color: #006699;
}

.profile-side-modal
.profile-section
.profile-info
.social-list
li
a.lnkdn-icon.active
.icon {
    background:url("assets/images/sprite.png") no-repeat;
    background-position: 1px -16px;
}

.profile-side-modal
.profile-section
.profile-info
.social-list
li
a.lnkdn-icon
.icon {
    background:url("assets/images/sprite.png") no-repeat;
    background-position: 1px 3px;
    width: 20px;
    height: 20px;
}

.profile-side-modal
.profile-section
.profile-info
.social-list
li
a.twitter-icon.active {
    background: #50abf1;
    border-color: #50abf1;
}

.profile-side-modal
.profile-section
.profile-info
.social-list
li
a.twitter-icon.active
.icon {
    background:url("assets/images/sprite.png") no-repeat;
    background-position: -18px -16px;
}

.profile-side-modal
.profile-section
.profile-info
.social-list
li
a.twitter-icon
.icon {
    background:url("assets/images/sprite.png") no-repeat;
    background-position: -18px 4px;
}

.profile-side-modal
.profile-section
.profile-info
.social-list
li
a.github-icon.active {
    background: #323131;
    border-color: #323131;
}

.profile-side-modal
.profile-section
.profile-info
.social-list
li
a.github-icon.active
.icon {
    background:url("assets/images/sprite.png") no-repeat;
    background-position: -38px -16px;
}

.profile-side-modal
.profile-section
.profile-info
.social-list
li
a.github-icon
.icon {
    background:url("assets/images/sprite.png") no-repeat;
    background-position: -38px 4px;
}


.profile-side-modal .action-btn-listing li a .icon-ui.ic-delete:before {
    width: 22px;
    height: 21px;
    background-position: -198px -36px;
}

.profile-side-modal .action-btn-listing {
    top: 170px;
    width: 100%;
    background: #fff;
    z-index: 100;
}
.profile-side-modal .action-btn-listing ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #f5f5f9;
    margin-bottom: 14px;
}

.profile-side-modal .action-btn-listing li {
    margin-right: 30px;
}

.profile-side-modal .action-btn-listing li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.profile-side-modal .action-btn-listing li a:hover .icon-ui {
    border: 1px solid rgba(222, 222, 235, 0.09);
    -webkit-box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.22),
    0px 2px 4px rgba(20, 24, 47, 0.2);
    box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.22),
    0px 2px 4px rgba(20, 24, 47, 0.2);
}

.profile-side-modal .action-btn-listing li a .icon-ui {
    background: #ffffff;
    border: 1px solid rgba(222, 222, 235, 0.07);
    -webkit-box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12),
    0px 2px 4px rgba(20, 24, 47, 0.1);
    box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12),
    0px 2px 4px rgba(20, 24, 47, 0.1);
    border-radius: 100%;
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 8px;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.profile-side-modal .action-btn-listing li a .icon-ui:hover {
    border: 1px solid rgba(222, 222, 235, 0.09);
    -webkit-box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.22),
    0px 2px 4px rgba(20, 24, 47, 0.2);
    box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.22),
    0px 2px 4px rgba(20, 24, 47, 0.2);
}

.profile-side-modal .action-btn-listing li a .icon-ui:before {
    position: relative;
    left: 0;
    background:url("assets/images/ic-seprite.svg");
    content: "";
    top: 0;
}

.profile-side-modal .action-btn-listing li a .icon-ui.ic-email:before {
    width: 22px;
    height: 18px;
    background-position: -3px -39px;
}

.profile-side-modal .action-btn-listing li a .icon-ui.ic-Phone:before {
    width: 22px;
    height: 20px;
    background-position: -44px -38px;
}

.profile-side-modal .action-btn-listing li a .icon-ui.ic-task:before {
    width: 22px;
    height: 20px;
    background-position: -81px -38px;
}

.profile-side-modal .action-btn-listing li a .icon-ui.ic-note:before {
    width: 22px;
    height: 20px;
    background-position: -121px -38px;
}

.profile-side-modal .action-btn-listing li a .icon-ui.ic-export:before {
    width: 22px;
    height: 20px;
    background-position: -163px -38px;
}

.profile-side-modal .action-btn-listing li a .icon-ui.ic-more:before {
    width: 22px;
    height: 19px;
    background-position: -231px -6px;
}

.profile-side-modal .action-btn-listing li a .icon-ui.ic-delete:before {
    width: 22px;
    height: 21px;
    background-position: -198px -36px;
}

.profile-side-modal .action-btn-listing li a .text-ui {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #414766;
    width: max-content;
}

.profile-side-modal .action-btn-listing li a.delete-btn .text-ui {
    color: #ff404c;
}

.profile-side-modal .tags-block {
    position: relative;
}

.profile-side-modal .tags-block .tag-fields {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.profile-side-modal .tags-block .tag-fields label {
    width: 70px;
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #8e96b6;
}

.profile-side-modal .tags-block .tag-fields .add-tag-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #414766;
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12),
    0px 2px 4px rgba(20, 24, 47, 0.1);
    box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12),
    0px 2px 4px rgba(20, 24, 47, 0.1);
    border-radius: 8px;
    padding: 4px 12px;
}

.profile-side-modal .tags-block .tag-fields .add-tag-wrapper {
    display: none;
}

.profile-side-modal .tags-block .tag-fields.active .add-tag-btn {
    display: none;
}

.profile-side-modal .tags-block .tag-fields.active .add-tag-wrapper {
    display: block;
}

.profile-side-modal .tags-block .view-detail-btn {
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.12px;
    color: #566ff4;
    position: absolute;
    right: 20px;
    top: 0;
}

.profile-side-modal .tags-block .view-detail-btn:after {
    background:url("assets/images/ic_arrow_down.svg") no-repeat;
    width: 10px;
    height: 10px;
    position: absolute;
    content: "";
    top: 5px;
    right: -13px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.profile-side-modal .about-contact .form-group {
    margin-bottom: 10px;
}

.profile-side-modal .about-contact .form-group label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #8e96b6;
    margin-bottom: 8px;
}

.profile-side-modal .about-contact .form-group p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #242946;
}

.profile-side-modal .about-contact .form-group .data-listing {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
}

.profile-side-modal .about-contact .form-group .data-listing li {
    background: #f5f5f9;
    border: 1px solid #efeff8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 2px 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #414766;
    margin-bottom: 9px;
    margin-right: 10px;
    padding-top: 8px;
}

.profile-side-modal .about-contact .form-group .data-listing .vm-btn {
    background: #ffffff;
    border: 1px solid #efeff8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 14px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #3b53d1;
    cursor: pointer;
}

.profile-side-modal .editable-field {
    height: 25px;
    cursor: text;
    position: relative;
    /* display: inline-flex; */
    align-items: center;
    width: 100%;
    padding-bottom: 2px;
    border-bottom: 1px solid transparent;
}

.profile-side-modal .slide-up {
    height: 80px !important;
    opacity: 1 !important;
}
.profile-side-modal .btns-modal-group {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    height: 0;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 17px 100px;
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease;
}
.profile-side-modal .btns-modal-group button {
    margin: 0 20px;
    width: 150px;
    min-width: 150px;
}

.edit-description-container {
    height: 150px;
}
.edit-description-container .description-toggle-container {
    border: 1px solid #ddd;
    border-radius: 5px;
    height: max-content;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    outline: none;
    transition: all 0.2s ease;
}
.edit-description-container .description-toggle-container:hover {
    border: 1px solid #ccc;
}

.edit-description-container textarea {
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 14px;
    outline: none;
    border: 1px solid rgba(0, 0, 250, 0.4);
    border-radius: 5px;
    resize: none;
}
#leads div.head_block  .properties-and-export {
  display: flex;
    margin-left: 20px;
}
