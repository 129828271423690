.custom-lead-export ul {
  flex-direction: column;
  align-items: start !important;
  padding: 10px 0 0 0!important;
}

.custom-lead-export ul li {
  padding: 10px!important;
  width: 100%!important;
}
.custom-lead-export ul li:hover {
    background :  #5369DF;
    color:#fff;
    cursor: pointer;
}
