.comparison-table {
  padding: 16px;
}

.comparison-table__title {
  font-size: 24px;
  text-align: center;
  margin: 49px;
  color: var(--Grey---900, #33374d);
  text-align: center;
  font-size: 27.4px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 118.421% */
}

.comparison-table__wrapper {
  overflow-x: auto;
}

.comparison-table__category {
  font-weight: bold;
  padding: 15px 20px !important;
  background-color: #f6f6f9 !important;
}

.comparison-table__row--expandable {
  cursor: pointer;
}

.comparison-table__feature {
  display: flex;
  justify-content: space-between;
}

.comparison-table__sub-row td.table__cell:first-child .comparison-table__sub-feature {
  margin-left: 20px;
  
}

.comparison-table__icon--check {
  color: green;
}

.comparison-table__icon--minus {
  color: red;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table__header {
  background-color: #f4f4f4;
  font-size: 17.7px;
}

.table__row {
  border-bottom: 1px solid #ddd;
}
.table__row:hover td{
  background: #f6f6f9!important;
  transition: all .5s ease ;
}

.table__cell {
  padding: 10px;
  text-align: left;
  padding: 15px 20px !important;
}
.table__cell:first-child {
  max-width: 130px;
}

.table__cell--header {
  font-weight: bold;
}
