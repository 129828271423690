/* .lead-table.table-content td:not(.lead-table.table-content td:nth-child(1)) {
  min-width: 200px !important;
} */
.lead-table.table-content td,
.lead-table.table-content th {
  padding-left: 20px !important;
  width: 100%;
}
.lead-table.table-content th,
.lead-table.table-content td {
  width: auto;
  max-width: auto;
  min-width: auto;
}
.lead-table.table-content th:last-child,
.lead-table.table-content td:last-child {
  width: 100% !important;
  max-width: auto !important;
  min-width: 100% !important;
  /* background: #000; */
}

.lead-table.table-content tr {
  position: static !important;
}

.lead-table.table-content tr:hover a {
  text-decoration: none !important;
  color: black !important;
}

.lead-table.table-content tr:hover .action-btns {
  opacity: 1;
  visibility: visible;
}

.lead-table.table-content .checkbox-container {
  display: flex;
  width: 419px;
  position: sticky !important;
  left: 0;
  /* background: #fff; */
  justify-content: flex-start;
  align-items: center;
  z-index: 8;
  box-shadow: 10px 6px 10px rgba(222, 222, 224, 0.9);
  min-width: 419px !important;
  /* margin-right: 20px; */
}
.lead-table.table-content .checkbox-container.white {
  background: #fff;
}
.lead-table.table-content .checkbox-container .view-profile {
  margin-left: 10px;
  width: 240px;
}
.lead-table.table-content .checkbox-container .view-profile .squared-logo {
  margin-right: 0.7rem !important;
  margin-left: 0.4rem !important;
}

.lead-table.table-content .checkbox-container .prospect-icons {
  display: flex;
  height: 100%;
  justify-content: start;
  align-items: center;
  width: auto;
  padding-right: 0.1rem;
  position: relative;
}
.lead-table.table-content .action-btns {
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: linear-gradient(
    89.93deg,
    rgba(250, 250, 252, 0) 0.04%,
    #fafafc 24.96%,
    #fafafc 74.95%,
    rgba(250, 250, 252, 0) 99.92%
  );
  width: 234px;
  position: absolute;
  left: 260px;
  top: 10px;
  padding: 6px 20px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.lead-table.table-content .action-btns .act-btn {
  background: #ffffff;
  border: 1px solid rgba(222, 222, 235, 0.07);
  -webkit-box-shadow:
    0px 0px 2px rgba(20, 24, 47, 0.12),
    0px 2px 4px rgba(20, 24, 47, 0.1);
  box-shadow:
    0px 0px 2px rgba(20, 24, 47, 0.12),
    0px 2px 4px rgba(20, 24, 47, 0.1);
  border-radius: 100%;
  width: 34px;
  height: 34px;
  margin: 0 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.lead-table.table-content .action-btns .act-btn.view-btn .icon {
  position: relative;
  left: 0;
  background: url('assets/images/ic-seprite.svg');
  background-position: -164px -5px;
  top: 0;
  width: 23px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 18px;
}
.lead-table.table-content .action-btns .act-btn.list-btn .icon {
  position: relative;
  left: 0;
  background: url('assets/images/ic-seprite.svg');
  background-position: -198px -6px;
  top: 0;
  width: 23px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 18px;
}
.lead-table.table-content .action-btns .act-btn.email-btn .icon {
  position: relative;
  left: 0;
  background: url('assets/images/ic-seprite.svg');
  background-position: -3px -39px;
  top: 0;
  width: 23px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 18px;
}
.lead-table.table-content .action-btns .act-btn.more-btn .icon {
  position: relative;
  left: 0;
  background: url('assets/images/ic-seprite.svg');
  background-position: -231px -6px;
  top: 0;
  width: 23px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 19px;
}

.lead-table.table-content .show-data {
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 5px;
  padding-right: 10px;
  border: 1px solid #999;
  color: #999;
  font-size: 14px;
  font-weight: 500;
}
.lead-table.table-content .show-data img {
  margin-bottom: -3px;
  margin-left: -6px;
  width: 30px;
  margin-right: 0px;
}

.prospect-inner {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  justify-content: space-between;
}

.prospect-col {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
  align-items: start;
}

.prospect-col div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #414766;
}

.prospect-border-r {
  position: absolute;
  height: 100%;
  width: 0.75rem;
  right: 0;
  bottom: 0;
  background: linear-gradient(270deg, rgba(222, 222, 224, 0) 0%, #dedee0 100%);
}

.prospect-icons .work-email-icon-img {
  position: absolute;
  top: -2px;
  right: -2px;
  pointer-events: none;
  cursor: pointer;
}

.actions-ctn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  padding-left: 0;
}

.actions-ctn li {
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
  padding: 10px;
  cursor: pointer;
  /* text-align: center; */
}

.actions-ctn li:hover {
  background-color: #f5f5f9;
}

.work-email-icon-btn,
.phone-icon-btn,
.linkedin-icon-btn,
.actions-icon-btn,
.copy-icon-btn {
  padding: 5px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.work-email-icon-btn:hover,
.phone-icon-btn:hover,
.linkedin-icon-btn:hover,
.actions-icon-btn:hover,
.copy-icon-btn:hover {
  background: #f0f0f0;
}

.is-icon-active {
  background: #f0f0f0;
}

.first-leads-list-col {
  width: 100%;
  max-width: 350px;
  overflow: hidden;
}

.first-leads-list-col .squared-logo {
  margin-right: 0.5rem;
}

.prospect-col .full-name {
  margin: 0;
  white-space: nowrap;
}
.prospect-col .full-name:hover {
  text-decoration: underline !important;
}
.direct-email-ctn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 2px;
}

.direct-email-ctn p {
  font-size: 13px;
  font-weight: 600 !important;
  color: #797f99 !important;
}

.show-personal-email {
  margin-left: 1.8rem;
  display: flex;
  gap: 1rem;
}

.more-direct-email-btn {
  font-size: 12px;
  font-weight: 400;
  color: #9298b7;
  margin-left: 1.8rem;
  margin-top: 0.75rem;
}

.more-direct-email {
  margin-left: 1.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.more-direct-email-li {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.phone-modal {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.phone-modal p {
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #797f99 !important;
}

.phone-modal-top {
  margin-left: 1.8rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.phone-modal-icons {
  display: flex;
  gap: 0.8rem;
  justify-content: end;
  align-items: center;
}

.show-more-phone-btn {
  font-size: 12px;
  font-weight: 400;
  color: #9298b7;
  margin-left: 1.8rem;
}

.show-more-phones-ul {
  /* margin-left: 1.8rem; */
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
}

.show-more-phone-li {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.phone-number-ctn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.phone-number-ctn-text {
  font-size: 13px;
  font-weight: 600 !important;
  color: #797f99 !important;
}

.phone-number-show-data-btn {
  margin-left: 1.8rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
