#coin-usage {
    margin-right: 20px;
    border-radius: 20px;
    padding: 1px 7px;
    padding-left: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

}
#coin-usage span {
    font-size: 13px;
    font-weight: 600;
}
#coin-usage img{
    width: 25px;
    margin-bottom: -3px;
}