#avatars {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
}

#avatars .avatar {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-right: -20px;
  border: 4px solid #ffffff;
  background: #ffdede;
  color: #b54848;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  font-weight: bold;
  text-transform: uppercase;
}

#avatars .initials {
  background-color: #4e5479;
  color: #ffffff;
}

#avatars .remaining {
  background-color: #4e5479;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
}
