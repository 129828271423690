.member-permissions {
  position: relative;
}

.member-permissions .calltoaction-permission {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.member-permissions .calltoaction-permission a {
  color: #fff;
}
