.custom-member-select .custom-select-picker{
    margin:0 10px;
    padding: 0 10px;
    box-shadow: 0 1px 4px 0 rgb(20 24 47/10%);
    border-radius: 5px;
}
.custom-member-select .custom-select-picker input {
    border: none;
    padding: 5px;
    padding-left: 10px;
    width: 170px;
    color:#3B53D1;
}
.custom-member-select .custom-select-picker input::placeholder {
    color: #666;
}
.custom-member-select .custom-select-picker .custom_tag_name{
    background: #ffdede;
    color: #b54848;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 11px;
    margin-right: 7px;
}