.add-note-section-container {
 padding: 5px;
}
.add-note-section-container  .add-note-title-container {
   display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 40px;
}
.add-note-section-container .add-note-title-container  h2{
  font-size: 16px;
    margin-top: 10px;
}
.add-note-section-container .add-note-title-container  p{
    font-size: 16px;
    margin-top: 10px;
    color: #3b53d1;
    cursor: pointer;
}

.add-note-section-container textarea {
  width: 100%;
    height: 150px;
    outline: none;
    border-color: #ccc;
    border-radius: 5px;
    padding: 10px;
}
.add-note-section-container textarea::placeholder{
    color:#999
}
.add-note-section-container .add-note-buttons {
   margin: 10px 0;
    display: flex;
    justify-content: flex-end;
}

.add-note-section-container .add-note-buttons button{
    width: 100px;
    margin-left:  15px;
}
.add-note-section-container  .note-error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: green;
    margin: 70px 0;
}
.add-note-section-container  .note-error-message  i {
    margin-right: 10px;
    font-size: 20px;
}