.choose-admins-container .admins-list {
 display: flex;
    flex-wrap: wrap;

}
.choose-admins-container .checkbox-wrap {
    margin-right:20px;
    margin-top:20px;
    width:max-content;
}
.choose-admins-container .checkbox-text {
 margin-left: 30px;
}
