#input-tags {
  background: #fff;
  box-sizing: border-box;
}
#input-tags span > input {
  background: #fff;
  height: 100%;
  border: none;
  padding: 10px;
  display: inline-block;
}
.input-tags-list {
  border: 1px solid #d5d7e4;
  padding: 5px;
  border-radius: 8px;
  width: 100%;
  background: #ffff;
  position: relative;
  top: 0;
  height: 100%;
}
#input-tags .input-tag {
  background: #fff;
  color: rgba(36, 41, 70, 1);
  padding: 5px 10px;
  border-radius: 20px;
  margin-left: 10px;
  border: 1px solid rgba(121, 127, 153, 1);
  position: relative;
  margin-top: 3px;
  margin-bottom: 3px;
  display: inline-block;
}
#input-tags .input-tag.plus {
  cursor: default;
}
/*
#input-tags div.show-all-emails {
  position: absolute;
  top: 50px;
  background: #fff;
  color: #000;
  z-index: 2;
  padding: 10px;
  min-width: 100px;
  border-radius: 5px;
  border: 1px solid rgba(121, 127, 153, 1);
  display: none;
}
#input-tags:has(.input-tag.plus:hover) div.show-all-emails {
  display: block;
}*/
#input-tags .invalid {
  border-color: rgba(230, 109, 109, 1) !important;
  background: rgba(230, 109, 109, 0.1) !important;
}
#input-tags .licence-required {
  border-color: rgba(252, 39, 128, 1);
  color: rgba(252, 39, 128, 1);
}
#input-tags .tag-value img {
  width: 19px;
}
#input-tags .input-tag .close-tag-2 {
  display: inline-flex;
  cursor: pointer;
}
#input-tags .close-tag-2 img {
  margin-left: 7px;
  margin-right: -4px;
  width: 11px;
}
