#onboarding-footer {
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
}

#onboarding-footer .copyright {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 16px;
}
