#wk-list .linkedin-workflow-container {
  margin-top: 10px;
  color: #ff7b25;
  line-height: 1.2;
}

#wk-list .linkedin-span-container {
  background-color: #ffeead;
  padding: 5px;
  border-radius: 10px;
  display: inline-block;
}

#wk-list .linkedin-flex-container {
  display: flex;
  gap: 3px;
  padding: 0 5px;
}

#wk-list .linkedin-icon {
  color: #ff7b25;
}

#wk-list .linkedin-text {
  font-size: 14px;
  font-weight: 500;
  color: #595f79;
}
