.org-settings .new-organization-ui  {
 padding: 0;
}
.org-settings p {
    display: block !important;
}
.org-settings .info-card {
    padding: 20px;
    margin-top: 30px;
}
.org-settings .edit-name-picture .form-edit-container {
    display: flex ;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.org-settings .edit-name-picture .form-edit-container  .name-pic {
    display: flex;
    align-items: center;
}
.org-settings .edit-name-picture .form-edit-container  .name-pic .form-group {
    margin-left: 50px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start;
    margin-bottom: 2px;
    border-left: 1px solid #ccc
}

.org-settings .edit-name-picture .form-edit-container  p{
    color: #595f79;
    font-size: 14px !important;
    letter-spacing: 0.14px;
    line-height: 20px;
    margin-bottom: 8px;
}
.org-settings .edit-name-picture .form-edit-container input {
    width: 300px;
}
