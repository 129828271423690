#bulk-enrichment .progress-steps-container {
  display: flex;
  gap: 10px;
  align-items: end;
}
#bulk-enrichment .progress-steps-container span {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.28px;
  color: #a9afc6;
  margin-bottom: -5px;
}
#bulk-enrichment .progress-steps-container-main {
  display: flex;
  flex-direction: column;
}
#bulk-enrichment .steps {
  display: flex;
}
#bulk-enrichment .steps i {
  margin-right: 3px;
}
#bulk-enrichment .steps h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.28px;
  color: #111833;
}
#bulk-enrichment .pending {
  color: #a9afc6 !important;
}
#bulk-enrichment .progress-bar {
  width: 100%;
  height: 4px;
  border-radius: 5px;
}
#bulk-enrichment .progress-bar::-webkit-progress-bar {
  background-color: #e8eaf5; /* Background color */
}

#bulk-enrichment .progress-bar::-webkit-progress-value {
  background-color: #495377; /* Progress bar color */
}
#bulk-enrichment .progress-bar::-moz-progress-bar {
  background-color: #495377;
}
#bulk-enrichment .modal-container-bottom-buttons button a {
  z-index: 0;
}
