.input-tags {
  position: relative;
  overflow: hidden;
}
.input-all-tags {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(50%);
  display: block;
}
.input-tag {
  background: rgb(219, 213, 255);
  color: #3b53d1;
  padding: 5px 10px;
  border-radius: 20px;
  margin-left: 10px;
  border: 1px solid transparent;
  position: relative;
}
.input-tag .close-tag {
  position: absolute;
  width: 15px;
  height: 15px;
  top: -2px;
  right: -5px;
  background: #777;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
    cursor: pointer;
}
.input-tag .close-tag img {
  width: 8px;
}
