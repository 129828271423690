#bulk-home {
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 1px 3px 0 rgba(20, 24, 47, 0.12);
  border-radius: 10px;
  width: 100%;
  height: 80vh;
  position: relative;
}
#bulk-home .file-upload-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
  width: 100%;
  height: 100%;
}

#bulk-home .file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}
#bulk-home .file-upload h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 25px;
}
#bulk-home .file-upload-bottom {
  background-color: red;
  width: 50%;
}
#bulk-home .file-upload-bottom p {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
#bulk-home .pop-up-container {
  height: 100%;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(3px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

#bulk-home .launches-limits {
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
  display: flex;
  gap: 14px;
}

#bulk-home .infinite-limits {
  display: flex;
  align-items: center;
  gap: 4px;
}

#bulk-home .launches-limits p {
  animation: fadeInUp 0.5s ease-out forwards;
  font-size: 14px;
  font-weight: 500;
  color: #5369df;
  background-color: #f3f3f3;
  padding: 5px 8px;
  border-radius: 8px;
}

#bulk-home .info-credit {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 40vw;
}

#bulk-home .info-credit p {
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.28px;
}
