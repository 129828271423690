#final-confirmation-modal .final-confirmation-title {
    display: flex;
    gap: 5px;
    align-items: center;
}

#final-confirmation-modal .final-confirmation-title img {
    width: 20px;
    height: auto;
}

#final-confirmation-modal .final-confirmation-title h2 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: #4D58F3;
}

#final-confirmation-modal h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: -0.12px;
    text-align: left;
    color: #0D1021;
    padding-top: 10px;
    margin: 0;
}

#final-confirmation-modal h3 {
    padding-top: 14px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #495377;
    margin: 0;
}

#final-confirmation-modal .checkboxes {
    margin-top: 20px;
    border: 1px solid #EBEBEB;
    box-shadow: 0px 4px 14px 0px #0000000D;
    padding: 24px ;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}


#final-confirmation-modal .icon {
    margin-right: 5px;
}

#final-confirmation-modal .checkbox-date {
    font-weight: 600;
    margin-left: 3px;
}

#final-confirmation-modal .buttons-container {
    width: 100%;
    justify-content: end;
    gap: 10px;
    margin-top: 14px;
}

#final-confirmation-modal .buttons-container button {
    padding: 0 24px
}

