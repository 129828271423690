#compare-plans {
  position: relative;
  background: #fff;
  padding: 20px;
  padding-top: 50px;
  font-size: 14px;
}
#compare-plans .plan-info-container {
  position: relative;
}
#compare-plans .plan-info-container .head-wrapper {
  display: flex;
  width: 100%;
}
#compare-plans .plan-info-container .fix-head {
  position: sticky;
  z-index: 100;
  top: 75px;
}
#compare-plans .plan-info-container .itm-head-wrapper {
  display: block;
  width: 100%;
  justify-content: flex-end;
  border-radius: 20px;
  background: #fff;
  overflow: hidden;
  border: 1px solid #ccc;
}
#compare-plans .plan-info-container .plan-btn {
  margin: 0;
  font-size: 15px;
  white-space: nowrap;
}
#compare-plans .plan-info-container h4 {
  margin-top: -10px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 24px;
}
#compare-plans .plan-info-container .itm-choose-plan {
  display: flex;
  width: 100%;
  padding: 10px;
}
.top-gradient-line.compare {
  position: static;
  height: 7px;
  margin-top: 0;
}
#compare-plans .plan-info-container .itm-head:not(.itm-head:first-child) {
  border-left: 1px solid #dedede;
}
#compare-plans .plan-info-container .itm-head-wrapper .itm {
  width: 25%;
  padding: 20px 30px;
}
#compare-plans .plan-info-container .itm {
  width: 25%;
  text-align: center;
  padding: 10px;
}
#compare-plans .plan-info-container .itm img {
  width: 18px;
  margin-left: 5px;
}
#compare-plans .plan-info-container .permission-name,
#compare-plans .plan-info-container .bdy-action-name {
  text-align: left;
  padding: 10px;
  width: 16%;
}
#compare-plans .plan-info-container .permission-name {
  color: #4d68f8;
  font-size: 18px;
  margin-top: 20px;
  font-weight: bold;
}
#compare-plans .plan-info-container .bdy-wrapper {
  width: 100%;
}

#compare-plans .plan-info-container .bdy-itm-wrapper {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}

#compare-plans .line {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #eee !important;
  padding: 10px 0;
}

#compare-plans .line:first-child {
  border-bottom: 1px solid transparent !important;
}

#compare-plans .line:last-child {
  border-bottom: 1px solid transparent !important;
}

#compare-plans .custom-item-head {
  width: 430px !important;
  margin: 0 auto;
  position: relative;
  transform: translateX(-30px);
}
#compare-plans .custom-item-head .itm-choose-plan {
}
#compare-plans .custom-item-head .itm-head.itm:not(.bdy-action-name) {
  width: 100% !important;
}
#compare-plans .custom-bdy-wrapper .bdy-permission.itm {
  margin: 0 auto;
  transform: translateX(-34%);
}
