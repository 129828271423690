@media only screen and (max-width: 1300px) {
    .home .info-card.team-card a {
        margin-left: -29px;
    }
    #leads div.head_block {
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }
    #leads div.head_block .right_block {
        display: flex;
        align-self: flex-start;
        align-items: center !important;
        margin-top: 20px;
    }
    #workflow .head_block{
        flex-direction: column;
    }
    #workflow .head_block .right_block{
        align-self: flex-end !important;
        justify-self: flex-end !important;
    }
}
@media only screen and (max-width: 1100px) {
    .home .info-card.team-card a {
        margin-left: -29px;
        font-size: 12px;
    }
    .home .workflow-card a {
        font-size: 12px;
    }
}
@media only screen and (max-width: 1170px) {
    #leads .page-header .row .align-self-center {
        display: flex;
    }
    .content-wrapper-ui .content-wrap {
        top: 50px;
    }
}
@media only screen and (max-width: 1120px) {
    #leads .page-header .row .align-self-center {
        display: block;
    }
    #leads button.dropdown-toggle {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 900px) {
  .content-wrapper-ui .content-wrap {
    width: 100% !important;
    min-width: 100%;
    position: absolute;
    top: 150px;
  }
  .side-nav-container .navbar-nav {
    background: #fff !important;
    display: block;
    width: auto !important;
    height: 100vh !important;
    box-shadow: 5px 10px 5px rgba(0, 0, 0, 0.1);
    padding: 0 10px;
    position: fixed !important;
    left: 0px !important;
    top: 130px !important;
    border-radius: 5px;
    padding-top: 50px !important;
  }

}

