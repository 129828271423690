.custom-edit-company {
    border: 1px solid #f5f5f9;
    border-radius:5px;
    padding: 10px;
    width: 100%;
    font-size: 14px;
    position: relative;
}
.custom-edit-company input,
.custom-edit-company textarea{
    border:none;
    border-bottom: 1px solid #ddd;
    background: transparent;
    box-shadow: none;
    margin-left: 20px;
    padding:2px 5px;
    outline: none;
}
.company-edit-container {
    display: flex;
    margin: 5px 0px;
}
.company-edit-container >span {
    width: 80px;
}
.company-edit-container >div {
    display: flex ;
    justify-content: space-between;
    margin:5px 0;
    color: #777;

}

.company-edit-container .country-field {
    padding: 5px;
    font-size: 13px;
    cursor: pointer;
}
.company-edit-container .country-field:hover {

}