#view-permission {
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
    border-radius: 10px;
    height: 80vh;
}

#view-permission img.blur-bg {
    filter: blur(5px) grayscale(.5);
    position: relative;
    user-select: none;
    width: 100%;

}
#view-permission .view-permission-message .message-box  .message-header {
    margin: 50px auto;
    width: 70%;
    color: #333;
}

#view-permission .view-permission-message {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5555;
}
#view-permission .view-permission-message .message-box {
    background: #fff;
    width: 50%;
    padding: 50px;
   text-align: center;
    background: #ffffff;
}

#view-permission .view-permission-message  .champion-img {
    width: 80px;
}
#calltoaction-permission {
 position: relative;
}
#calltoaction-permission .crown {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    margin-top: -3px;
}
#calltoaction-permission.header-button a {
    background: #fff;
}
#calltoaction-permission.header-button  .img{
    position: absolute;
}
