.inner-description {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.zapier-modal-header {
  display: flex;
  gap: 30px;
  padding: 10px;
}

.zapier-modal-header-right {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.zapier-modal-header-right h2 {
  font-weight: 500;
  font-size: 28px;
}

.zapier-modal-header-right h3 {
  font-size: 18px;
  font-weight: 400;
  color: #797f99;
}
