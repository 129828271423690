.phone-user-edit-container .PhoneInputInput {
  border: none;
  width: 100%;
}
.phone-user-edit-container:hover {
  border-bottom: 1px solid #ccc;
}
.phone-user-edit-container:hover  .edit_user-icon {
    display: block;
}
.phone-user-edit-container .PhoneInputCountry {
  border: none !important;
  box-shadow: none !important;
  width: max-content !important;
}
.phone-user-edit-container .PhoneInputCountrySelect {
  padding: 0;
}
.phone-user-edit-container .PhoneInputCountrySelect option {
  text-align: left !important;
  justify-content: left !important;
}
