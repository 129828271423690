.workflow-tab-list.new_block_tab li a {
  font-size: 12px !important;
}
.custom-enrichment-attach input {
  width: 100%;
  margin: 20px 0;
  padding: 5px 6px;
  border-radius: 5px;
  box-shadow: none;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #ccc;
}
.custom-enrichment-attach input::placeholder {
  font-size: 13px;
}
/*.custom-enrichment-attach ul {
    width: 100%;
    padding:  10px;
    padding-top: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;

}*/

.wk-integration-container {
  padding-right: 5px;
}
.wk-integration-container .img-block {
  transform: scale(0.8);
}
.beta-badge {
    top: 0px;
    right: 0px;
    background: cadetblue;
    padding: 2px 7px;
    font-size: 13px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
}
