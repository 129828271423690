#plan-card {
  position: relative;
  padding: 24px !important;
  transition: transform 0.3s ease;
  z-index: 1;
  width: 100% !important;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  width: 24% !important;
  border: 2px solid transparent;
}
#plan-card:hover {
  border-color: #576df2;
  transition: border 0.8s ease !important;
}
#plan-card.expand {
  width: 33% !important;
}
#plan_tab .select-credits-container {
  min-height: 112px;
}
#plan_tab .select-credits-container p {
  font-size: 15.6px !important;
  font-size: 15.6px;
  font-weight: 400 !important;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
#plan_tab .subscription-type {
  font-size: 24.2px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.6000000238418579px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #757ca3;
}
#plan-card .recomended-value {
  position: absolute;
  top: -19px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 9999px;
  background: var(--Export-Credit, #b149ff);
  color: #fff;
  display: flex;
  padding: 8px 24.33px 8px 24px;
  justify-content: center;
  align-items: center;
  width: max-content;
}
#plan-card:hover {
  /* transform: scale(1.02); */
  cursor: pointer;
  z-index: 2;
}

#plan-card .permissions .permission-name {
  color: var(--Grey---900, #33374d);
  font-size: 15.6px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 153.846% */
  display: flex;
}
#plan-card .permissions .permissions__title {
  margin-bottom: 15px;
}
#plan-card .permissions .permission-name-txt {
  text-decoration: underline dotted #555;
  cursor: help;
}
#plan-card .permissions .permission-name img {
  margin-right: 15px !important;
  width: 14px !important;
}
#plan-card .unlimited-b2b {
  display: flex;
  align-items: center;
  gap: 5px;
  background: #ffe9b3;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  width: max-content;
  color: #495377;
}

#plan_tab .select-credits {
  font-size: 14px;
  padding: 0 0 24px 0;
  width: 100%;
}

#plan-card .select-credits p {
  /* margin: 8px 0; */
  font-size: 14px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */

  font-size: 14px;
  font-weight: 500;
  line-height: 31px;
  color: #33374d;
}
#plan-card .select-credits p img {
  margin-right: 14px;
}
/* #plan-card .select-credits p span {
  color: #0a66c2;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  margin-left: 10px;
  border: 1px solid #ccc;
  font-size: 17px;
  cursor: pointer;
} */
#plan-card .view-more-details {
  color: #555;
  position: absolute;
  bottom: 20px;
  text-decoration: underline;
}
#plan-card .view-more-details img {
  transform: rotate(90deg);
  margin-left: 5px;
  margin-top: -3px;
}
#plan-card .deactivate-plan {
  opacity: 1;
  /* pointer-events: none; */
  transition: all 0.5s ease;
}
#plan-card .activate-plan {
  opacity: 1;
  transition: all 0.2s ease;
}
#plan-card .billed-annually {
  position: relative;
  top: -10px;
  color: #495377;
}

#plan-permissions .plan-permissions-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

#plan-permissions h2 {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #111833;
  margin-bottom: 20px;
}

#plan-permissions h3 {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #111833;
}

#plan-permissions ul li {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #495377;
}

#plan-card .credits-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 24px;
  width: 100%;
}

#plan-card .credits-section .credits-section-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#plan-card .credits-section-title h3 {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #111833;
}

#plan-card .credits-section-title img {
  width: 13.33px;
  height: 13.33px;
}

#plan-permissions .credits-section-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#plan-permissions .credits-section-title h3 {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #111833;
}

#plan-permissions .credits-section-title img {
  width: 13.33px;
  height: 13.33px;
}

#plan-card .plan-card-title {
  align-items: start;
  width: 100%;
  font-size: 23.8px;
  font-weight: 600;
  color: #111833;
  margin-bottom: 5px;
}

#plan-card .discount_tag {
  width: 100px;
  padding: 6px 10px;
  border-radius: 8px;
  background: linear-gradient(90deg, #0eb763 0%, #1c8d9c 100%);
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
}

#plan-card .text-price {
  /* margin-top: 10px; */
}

#plan-card .text-price .font-sm {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #757ca3;
}

#plan-card .text-price .price {
  font-size: 30.4px !important;
  font-weight: 700;
  text-align: left;
  color: #111833;
}

#plan-card .text-price .price-subtitle {
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  color: #979797;
}

#static-plan span {
  font-size: 14px;
  font-weight: 500;
  color: #979797;
}

#plan-card.plan-card-selected {
  /* background-image: linear-gradient(180deg, #fff8f8 0%, #ffffff 48.44%) !important; */
  border: 2px solid #576df2;
}

.plan-card-default {
  background-color: #fff !important;
}

#plan-card .christmas-reduction-tag {
  position: absolute;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
  font-size: 28px;
  font-weight: 500;
  color: #e26285;
}

#plan-card .linethrough-value {
  color: #a5a9bb;
  text-decoration: line-through;
  font-weight: 400;
}
