#integration .top-integration-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#integration .top-integration-title input {
    border-radius: 10px;
    border: 0.5px solid #ffffffe5;
    box-shadow: 0px 2px 4px 0px #0d102114;
    box-shadow: 0px 0px 2px 0px #14182f1a;
    width: 300px;
    height: 36px;
}

#integration .top-integration-title input[type="text"] {
    background-image:url("assets/images/ic_settings_search.png");
    background-position: 10px center;
    background-repeat: no-repeat;
    padding-left: 35px;
}

#integration .integration-search-input {
    width: 396px;
    height: 40px;
    border: none;
}

#integration .request-integration {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 80px 0;
}

#integration .request-integration h2 {
    font-size: 20px;
    font-weight: 500;
}
