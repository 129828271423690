#onboarding-ctn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: relative;
}

#onboarding-ctn .container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

#onboarding-ctn .container-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

#onboarding-ctn .onboarding-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 37.5px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #0a0a0c;
}

#onboarding-ctn .onboarding-desc {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: center;
  color: #414143;
}

#onboarding-ctn .redirection-btn {
  text-align: center;
  width: 45%;
}

#onboarding-ctn .container .rating-ctn {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 300;
  line-height: 15.12px;
  letter-spacing: 0.01em;
  color: #414143;
}

#onboarding-ctn .container .rating-ctn .rating {
  display: flex;
  align-items: center;
  gap: 3px;
}
