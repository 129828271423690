.billing-info-card {
  padding: 20px;
  margin-bottom: 40px;
}
.billing-info-card h2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #414766;
}
.billing-info-card .header-manage {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.billing-info-card .header-manage .plan-name-tag {
  background: #f5f5f9;
  border-radius: 8px;
  padding: 7px;
  font-size: 15px;
  display: flex;
  align-items: center;
}
.billing-info-card .header-manage .plan-name-tag > span {
  display: flex;
  align-items: center;
}
.billing-info-card .header-manage .plan-name-tag small {
  color: #3b53d1;
  background: #fff;
  border-radius: 7px;
  font-size: 18px;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.billing-info-card .header-manage .plan-name-tag span {
  padding: 0 15px;
  color: #797f99;
}
.billing-info-card .header-manage .plan-name-tag span:first-child {
  border-right: 1px solid #ccc;
  color: #414766;
  font-weight: 500;
}
.billing-info-card .section-title {
  color: #797f99;
  font-size: 15px;
  margin: 10px 0;
}

.billing-info-card .addons-section-line {
  border-top: 1px solid #d5d7e4;
  padding: 20px 0;
  color: #797f99;
}
.billing-info-card .addons-section:last-child .addons-section-line {
  padding-bottom: 0;
}
.billing-info-card .addons-section-line .addons-line-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.billing-info-card .addons-section-line .addons-line-head > a {
  color: #3b53d1;
  font-weight: 500;
  cursor: pointer;
}
.billing-info-card .addons-section-line .addons-line-head span:first-child {
  color: #333;
  font-weight: 500;
  margin-right: 5px;
}
.addons-line-foot span:last-child,
.addons-line-foot span.cancel-renew {
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
}

.addons-line-foot .make-a-choice {
  /* background: linear-gradient(89.52deg, #4966FF 13.67%, #7760FF 32.07%, #B149FF 56.92%, #E54FF2 87.66%, #FF206E 128.2%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
    border-bottom: 1px solid #7760FF ;
  */
  color: #3b53d1;
  text-decoration: underline;
  font-weight: 600;
  margin-left: 20px;
  font-size: 13px;
}

.addons-line-foot span {
  color: #797f99;
  font-size: 13px;
}
.addons-line-foot .date {
  text-decoration: none !important;
  color: #333;
}
.billing-info-card .payment-info-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.billing-info-card .payment-info-head button {
  padding: 7px 20px;
  color: #3b53d1;
  border: 1px solid #3b53d1;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
}
.billing-info-card .current-subscription {
  background: #f5f5f9;
  padding: 20px;
  border-radius: 8px;
  margin: 15px 0;
  color: #797f99 !important;
}
.billing-info-card .current-subscription strong {
  color: #333;
}
.billing-info-card .current-subscription > div {
  display: flex;
  justify-content: space-between;
}
.billing-info-card .current-subscription > div:first-child {
  margin-bottom: 10px;
}
.billing-info-card .current-subscription .date {
  margin-left: 10px;
}
.billing-info-card .billing-line {
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.billing-info-card .billing-line .billing-line-method {
  display: flex;
  align-items: center;
}
.billing-info-card .billing-line .billing-line-method p {
  margin: 0;
  margin-right: 30px;
}
.billing-info-card .billing-line .billing-line-method button {
  margin-left: 20px;
}
.billing-info-card .billing-line p {
  color: #797f99;
  font-size: 15px;
  margin-bottom: 10px;
}
.billing-info-card .billing-line div {
  color: #333;
  font-size: 16px;
  font-weight: 500;
}
.billing-info-card .billing-line button {
  color: #3b53d1;
  font-weight: 500;
  cursor: pointer;
}

.billing-details-modal {
  max-height: 100%;
  overflow-y: auto;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.billing-details-modal::-webkit-scrollbar {
  display: none;
}

.billing-details-modal h2 {
  font-size: 22px;
  padding: 20px 0;
  color: #414766;
  border-bottom: 1px solid #d5d7e4;
}
.billing-details-modal .auto-renewal-terms {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.billing-details-modal .auto-renewal-terms h3 {
  font-size: 19px;
}
.billing-details-modal .auto-renewal-terms p {
  color: #72757e;
}

.billing-details-modal .billing-details-section {
  background: #f5f5f9;
  border-radius: 18px;
  padding: 20px;
}
.billing-details-modal .billing-details-section .details-line {
  padding-top: 10px;
  border-bottom: 1px solid #d5d7e4;
}
.billing-details-modal .billing-details-section .details-line:last-child {
  border: none;
}
.billing-details-modal .billing-details-section .details-line > div {
  background: #f5f5f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}
.billing-details-modal .billing-details-section .details-line > p {
  padding-top: 10px;
  padding-bottom: 20px;
  color: #72757e;
}
.billing-details-modal .total-amount-section {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  padding: 20px 0;
  padding-bottom: 3px;
}
.billing-details-modal p.billed-info {
  color: #72757e;
}
.billing-details-modal button {
  margin: 27px 0;
  font-size: 17px;
  padding: 0 25px;
}
.switch-to-yearly-subscription {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: linear-gradient(96.13deg, #e66d6d -17.49%, #db48be 150.79%);
  padding: 10px;
  border-radius: 7px;
  width: max-content;
  width: 400px;
  cursor: pointer;
}
.switch-to-yearly-subscription small {
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
}
.switch-to-yearly-subscription span {
  background: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  color: #db48be;
  white-space: nowrap;
  font-weight: 500;
}
