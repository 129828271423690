header {
  position: fixed;
  width: 100%;
  z-index: 1000 !important;
  background: url('assets/images/mainbg.jpg');
  -webkit-filter: saturate(100%);
  filter: saturate(100%);
}

.top-header {
  background: transparent;
  padding: 16px 0;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 99;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
.save25-button span {
  /*
    background: linear-gradient(89.52deg, #4966FF 13.67%, #7760FF 32.07%, #B149FF 56.92%, #E54FF2 87.66%, #FF206E 128.2%)!important;
    */
}
.save25-button i {
  color: #4966ff;
}
.earn-credits-ul li a i {
  opacity: 0.7;
  margin-right: 4px;
  font-size: 13px;
}
@media only screen and (max-width: 991px) {
  .top-header {
    min-height: 57px;
    padding: 10px 0;
  }
}

.top-header .site-logo {
  min-width: 230px;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}

.top-header .site-logo.s-logo {
  min-width: 35px;
  max-width: 40px;
  margin-right: 15px;
  overflow: hidden;
}

.top-header .site-logo a {
  width: 110px;
  display: inline-block;
}

.top-header .site-logo a img {
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .top-header .site-logo {
    width: 80px;
  }

  .top-header .site-logo img {
    width: 100%;
  }
}

.top-header .page-title p {
  margin-bottom: 0;
  color: #a9afc6;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.48px;
  line-height: 32px;
}

.top-header .back_btn a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #595f79;
  font-size: 16px;
  letter-spacing: 0.12px;
  line-height: 18px;
  text-shadow: -1px 1px 0 0 #ffffff;
}

.top-header .back_btn a .icon-ui {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  display: inline-block;
  line-height: 32px;
  text-align: center;
  color: #3b53d1;
  margin-right: 10px;
}

.top-header .inner-header-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: -4px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.top-header .inner-header-block .right-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
}

.top-header .inner-header-block .right-block .credit-point {
  margin-right: 20px;
}

.top-header .inner-header-block .right-block .credit-point .cp-btn {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.72);
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  display: inline-block;
  height: 38px;
  line-height: 38px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.12px;
  color: #0d1021;
  min-width: 140px;
  padding: 0 8px;
  -webkit-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
  text-decoration: none;
}

.top-header .inner-header-block .right-block .credit-point .cp-btn:hover {
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.16);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.16);
  background-color: white;
}

.top-header .inner-header-block .right-block .credit-point .cp-btn .icon {
  margin-right: 8px;
  display: inline-block;
  position: relative;
  top: -2px;
  width: 22px;
}

.top-header .inner-header-block .right-block .credit-point .cp-btn .icon img {
  width: 100%;
}

.top-header .inner-header-block .right-block .credit-point .cp-btn .icon img:hover {
  /* Réinitialiser la présentation visuelle au hover */
  background-color: transparent; /* Aucun fond */
  color: inherit; /* Hérite la couleur du texte du parent */
  text-decoration: none; /* Aucune décoration de texte */
  transform: none; /* Aucune transformation */
  transition: none; /* Aucune animation de transition */
  border: none; /* Aucun bord */
  box-shadow: none; /* Aucune ombre */
  filter: none; /* Aucun filtre, comme l'opacité ou les effets de flou */
}

.top-header .inner-header-block .right-block .notification-ui {
  padding-right: 18px;
  margin-right: 18px;
  position: relative;
  display: flex;
}

.top-header .inner-header-block .right-block .notification-ui #question-mark {
  /* margin-left: 18px; */
}

.top-header .inner-header-block .right-block .notification-ui:after {
  position: absolute;
  content: '';
  top: 8px;
  right: 0;
  height: 24px;
  width: 1px;
  border: 1px solid #d5d7e4;
}

.top-header .inner-header-block .right-block .notification-ui .ntfc-btn {
  display: inline-block;
  height: 38px;
  width: 38px;
  line-height: 38px;
  text-align: center;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.72);
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  position: relative;
  -webkit-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
}

.top-header .inner-header-block .right-block .notification-ui .ntfc-btn:hover {
  background-color: white;
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.3);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.3);
}

.top-header .inner-header-block .right-block .notification-ui .ntfc-btn .notification-icon {
  width: 24px;
  display: inline-block;
}

.top-header .inner-header-block .right-block .notification-ui .ntfc-btn .notification-icon img {
  width: 100%;
  display: none;
}

.top-header .inner-header-block .right-block .notification-ui .ntfc-btn .notification-icon:after {
  position: absolute;
  content: '';
  height: 23px;
  width: 24px;
  background: url('assets/images/ic_notification.png');
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  left: 0;
  right: 0;
  background-size: 100%;
  top: 8px;
  margin: auto;
  -webkit-animation: tada 2.5s ease;
  animation: tada 2.5s ease;
}

.top-header .inner-header-block .right-block .notification-ui .ntfc-btn .counter-icon {
  background: #e23f74;
  -webkit-box-shadow: 0 1px 2px 0 rgba(226, 63, 116, 0.16);
  box-shadow: 0 1px 2px 0 rgba(226, 63, 116, 0.16);
  min-width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: -6px;
  top: -2px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.12px;
  text-align: center;
  color: #fff;
  line-height: 16px;
  padding: 0 3px;
  /*-webkit-animation: pulse 2s ease-out 1;*/
}

.top-header .inner-header-block .right-block .profile-ui {
  margin-right: 10px;
}

.top-header .inner-header-block .right-block .profile-ui .profile-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  width: 100%;
  padding-right: 3rem;
  align-items: 'end';
  outline: none;
}

.top-header .inner-header-block .right-block .profile-ui .profile-btn:after {
  background: url('assets/images/ic_arrow_down.svg') no-repeat;
  width: 10px;
  height: 10px;
  border: none;
  margin: 0;
  position: absolute;
  right: -2px;
  top: 36%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.top-header .inner-header-block .right-block .profile-ui .user-icon {
  height: 38px;
  width: 38px;
  background-color: rgba(255, 255, 255, 0.72);
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  border-radius: 100%;
  line-height: 38px;
  text-align: center;
}

.top-header .inner-header-block .right-block .profile-ui .user-icon .icon-proifle-bg {
  width: 80%;
  display: inline-block;
}

.top-header .inner-header-block .right-block .profile-ui .user-icon .icon-proifle-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.top-header .inner-header-block .right-block .profile-ui .user-name {
  font-size: 16px;
  letter-spacing: -0.12px;
  line-height: 18px;
  margin-left: 8px;
  text-align: left;
  color: #0d1829;
}

.top-header .inner-header-block .right-block .profile-ui .user-name span {
  display: block;
  font-size: 12px;
  letter-spacing: 0.72px;
  line-height: 14px;
  color: #797f99;
  text-transform: uppercase;
  margin-top: 3px;
}
.wd-wrapper .tab-content .perfomance-detail .top-header {
  padding: 32px 40px;
}
.credits-drop-down {
  width: max-content;
  padding: 10px;
}
.credits-drop-down .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  margin-bottom: 20px;
  color: #495377;
}
.credits-drop-down button {
  display: block;
  margin: 0 auto;
}

header .profile-dropdown.notif {
  margin-top: 50px;
}

header .profile-dropdown .profile-dropdown-container {
  width: 280px;
  padding: 20px;
}
header .profile-dropdown .section-header {
  text-align: center;
  padding: 0px 10px;
  border-bottom: 1px solid #ececec;
}

header .profile-dropdown .section-header .email-ctn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

header .profile-dropdown .section-header h2 {
  font-size: clamp(14px, 2.5vw, 16px);
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 15px;
  letter-spacing: -0.12px;
  line-height: 18px;
  text-align: left;
  color: #0d1829;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 24ch;
}
header .profile-dropdown .section-header p {
  margin: 5px;
  color: #979797;
  font-size: 17px;
}
header .profile-dropdown .section-header button {
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 17px;
}
header .profile-dropdown .section-links a,
header .profile-dropdown .section-links .item {
  display: block;
  color: #495377;
  font-size: 15px;
  margin: 12px 0;
  font-weight: 400;
}
header .profile-dropdown .section-links a {
  display: flex;
  align-items: center;
}
header .profile-dropdown .section-links > a:hover {
  text-decoration: underline;
}
header .profile-dropdown .section-links .item.plans-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px 15px;
  padding-bottom: 5px;
}
header .profile-dropdown .section-links .item .currentPlan {
  color: #979797;
}
header .profile-dropdown .section-links .item > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .profile-dropdown .section-links .item > div a {
  color: #4d58f3;
  font-weight: 500;
  margin-top: 5px;
}
header .profile-dropdown .section-links.last {
  margin-top: 20px;
}
header .profile-dropdown .section-links.last a {
  color: #979797;
}

header .profile-dropdown .section-links img {
  width: 17px;
  margin-right: 10px;
}

header button.upgrade {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.72);
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  display: inline-block;
  height: 38px;
  line-height: 38px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.12px;
  color: #0d1021;
  padding: 0 8px;
  -webkit-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
  text-decoration: none;
  margin-right: 32px;
  padding-right: 1rem;
  padding-left: 1rem;
}

header button.upgrade:hover {
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.16);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.16);
  background-color: white;
}

header button.upgrade span {
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  font-weight: 500;
}
header button.upgrade span.without-gradient {
  -webkit-text-fill-color: #3b53d1 !important;
}
header button.upgrade span.with-purple {
  -webkit-text-fill-color: rgb(177, 73, 255) !important;
}

#total-credits {
  position: relative;
}

#total-credits .cta-btn {
  border-radius: 20px;
}

#total-credits .profile-dropdown {
  left: 30;
  top: -20;
  width: auto;
}
