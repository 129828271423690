#statistics_tab .credits-history-filters .credits-history-filters-head {
  display: flex;
  justify-content: space-between;
}
#statistics_tab
  .credits-history-filters
  .credits-history-filters-head
  .pickers-wp {
  display: flex;
  align-items: center;
}



