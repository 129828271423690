#dropdown-ctn {
  position: relative;
  width: 230px;
  background-color: #ffffff;
  border-radius: 10px;
}

#dropdown-ctn .button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 4vw;
  padding: 10px;
  border: 1px solid #14182f1a;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 #14182f1a;
  cursor: pointer;
}

#dropdown-ctn .is-open {
  border-bottom-width: 1px;
  border-bottom-color: #ffffff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #ffffff;
  z-index: 1000 !important;
}

#dropdown-ctn ul {
  position: absolute;
  width: 100%;
  border: 1px solid #14182f1a;
  border-top: none;
  border-radius: 0 0 6px 10px;
  box-shadow: 0 2px 4px 0 #14182f1a;
  max-height: 30vh;
  overflow-y: scroll;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #fff;
  z-index: 1000 !important;
}

#dropdown-ctn li {
  padding: 10px;
  cursor: pointer;
  z-index: 1000 !important;
}

#dropdown-ctn li:hover {
  background-color: #f2f2f2;
}

#dropdown-ctn .ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
