.user-blocked {
  display: flex;
  justify-content: center;
  align-items: center;
  color: darkred;
  height: 100vh;
}
.user-blocked h3 {
  color: #555;
}
