#feedback-modal .feedback-modal-title {
    display: flex;
    gap: 5px;
    align-items: center;
}

#feedback-modal .feedback-modal-title img {
    width: 20px;
    height: auto;
}

#feedback-modal .feedback-modal-title h2 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: #4D58F3;
}

#feedback-modal h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: -0.12px;
    text-align: left;
    color: #0D1021;
    padding-top: 10px;
    margin: 0;
}

#feedback-modal h3 {
    padding-top: 14px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #495377;
    margin: 0;
}

#feedback-modal .custom-radio-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
    width: 100%;
}

#feedback-modal .buttons-container {
    width: 100%;
    justify-content: end;
    gap: 10px;
    padding-top: 14px;
    border-top: 1px solid #D5D7E4;
    margin-top: 14px;
}

#feedback-modal .buttons-container button {
    padding: 0 24px
}

#feedback-modal .feedback-text-area {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
}

#feedback-modal .feedback-text-area textarea {
    width: 100%;
    height: Fixed(130px);
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #72757E;
    box-shadow: 0 7px 4px 0 #0000000D;
    gap: 16px;
    resize: none;
}

#feedback-modal .feedback-text-area textarea:focus {
    border-color: #595F79;
}

#feedback-modal .feedback-text-area textarea::placeholder {
    color: #72757E;
    font-size: 14px;
    font-weight: 400;
}


