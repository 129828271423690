.unpaid-notify .fa-check-circle {
  font-size: 80px;
  color: #249174;
}
.unpaid-notify .fa-check-circle
.unpaid-notify h2 {
  color: #249174;
}
.unpaid-notify  .fa-times-circle {
  font-size: 80px;
   color: tomato;
}
.unpaid-notify.error h2{
  color: tomato;
}