#mapping-modal .modal-container {
    width: 200%;
    right: 50%;
}

#mapping-modal .space {
    width: 15px
}

#mapping-modal .break {
    width: 150%
}

#mapping-modal .tbody-top {
    top: 40px
}

#mapping-modal .column {
    position: relative;
    right: 10px;
}

#mapping-modal .autocomplete {
    max-height: 225px;
    overflow-y: scroll;
    border-radius: 5px;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
    position: absolute;
    z-index: 10;
}

#mapping-modal .middle-arrow {
    width: 80%;
}

#mapping-modal .column-left {
    position: relative;
    left: 10px;
}

#mapping-modal .autocomplete-right {
    max-height: 225px;
    overflow-y: auto;
    border-radius: 5px;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
    position: absolute;
    z-index: 10;
    width: 100%;
}

#mapping-modal .delete-button {
    height: 30px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

#mapping-modal .delete-button img {
    padding: 35%;
}

#mapping-modal .add-new-field {
    color: #3b53d1;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.16px;
    line-height: 20px;
    padding-top: 10px;
}

#mapping-modal .add-new-field-button {
    height: 30px;
    padding: 10px;
    align-items: center;
    display: inline-block;
    cursor: pointer;
}

#mapping-modal .new-field-container {
   display: inline;
    cursor: pointer;
    margin-right: 5px;
}

#mapping-modal .loading {
    text-align: center;
}

#mapping-modal .loading img {
    height: 80px;
    margin-top: 80px;
    margin-bottom: 80px;
}
