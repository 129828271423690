#connection-modal-left-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #242946;
  padding: 14px 24px;
  width: 78%;
  height: 100%;
  overflow-y: scroll;
}

#connection-modal-left-container img {
  width: 100%;
  height: auto;
}

#connection-modal-left-container .description-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#connection-modal-left-container h1 {
  font-weight: 600;
  font-size: 20px;
}

#connection-modal-left-container .paragraph {
  margin-bottom: 20px;
}

#connection-modal-left-container .help {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 14px;
}

#connection-modal-left-container .help a {
  font-weight: 500;
  text-decoration: underline;
  color: #3b53d1;
}
