#subscription-infos {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

#subscription-infos .subscription-infos-wrapper {
  width: 100%;
}

#subscription-infos .subscription-infos-wrapper .sub-infos-general {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#subscription-infos .subscription-infos-wrapper .sub-infos-general p {
  font-weight: 600;
}

#subscription-infos .subscription-infos-wrapper .licenses-tag-ctn {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 0.25rem;
}

#subscription-infos .subscription-infos-wrapper .sub-infos-nb-licenses {
  width: fit-content;
  background-color: #ffffff;
  border-radius: 10px;
  font-size: 0.9rem;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  border: 1px solid #d5d7e4;
  color: #696969;
}

#subscription-infos .subscription-infos-wrapper .billed-ann-mon {
  margin-left: 0.5rem;
  font-size: 0.9rem;
  color: #696969;
}
