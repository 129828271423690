.optOut-from-email {
}
.optOut-from-email-container {
  border: 1px solid #ccc;
  background: white;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.optOut-from-email-container h1 {
  color: olivedrab;
  margin-bottom: 50px;
}
.optOut-from-email-container .content {
  width: 50%;
  margin: 0 auto;
  font-weight: 500;
    color: #555;
    font-size: 22px;
}
.optOut-from-email-container .error {
  color: darkred;
}
