#extra-credits-section {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

#extra-credits-section .extra-credits-wrapper {
  padding-top: 1.5rem;
  border-top: 1px solid #dcdcdc;
  width: 100%;
}

#extra-credits-section .extra-credits-wrapper .extra-credits-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.extra-credits-table table {
  width: 100%;
  margin-top: 30px;
  border-collapse: collapse;
}
.extra-credits-table table tr td {
  padding: 10px 0;
  font-size: 17px;
}
.extra-credits-table table td:last-child {
  text-align: right;
}

.extra-credits-table table thead td {
  color: #595f79;
}

.extra-credits-table table tbody td {
  color: #333;
}
