.unpaid-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 30px;
}
.top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  gap: 30px;
}
.top-container img {
  width: 20vw;
}
.top-container p {
  text-align: center;
}
.buttons {
  display: flex;
  gap: 20px;
}
