.linkedin-btn-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.linkedin-btn-right a,
.linkedin-btn-right button {
  width: 83%;
  display: flex;
  justify-content: center;
}

.linkedin-btn-right .linkedin-cta {
  display: flex;
  justify-content: center;
  width: 100%;

}

.linkedin-btn-right .linkedin-cta a {
  width: 83%;
}

.responsive-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
}

.responsive-text FormattedMessage {
  max-width: 100%;
  font-size: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dl-extension {
  max-width: 100%;
  font-size: 0.75em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 600px) {
  .responsive-text FormattedMessage {
    font-size: 0.8em;
  }
}
