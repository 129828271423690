#enrichment-options .card-container {
  width: 30%;
  height: 180px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #bbb7c8;
}
#enrichment-options .card-container-bg {
  background-color: #e6e9f9;
}
#enrichment-options .card-container h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}
#enrichment-options .checkbox-container {
  display: flex;
  gap: 10px;
}
#enrichment-options .checkbox-container h2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #bbb7c8;
}
#enrichment-options .active-text {
  color: #5167dc !important;
}
#enrichment-options .linebreak {
  border: 1px solid #f1f1f1;
  width: 90%;
  margin: 10px 0;
}
#enrichment-options .mandatory {
  display: flex;
  align-items: center;
  gap: 5px;
}
#enrichment-options .mandatory input {
  margin-bottom: 8px;
}
