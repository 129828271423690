#summary-addon .addon-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#summary-addon .addon-title .addon-price {
  font-size: 16px;
  font-weight: 500;
  color: #0d1021;
}

#summary-addon .credit-title-free {
  display: flex;
  align-items: center;
  gap: 10px;
}

#summary-addon .credit-title-free {
  display: flex;
  align-items: center;
  gap: 5px;
}

#summary-addon .credit-title-free .add-on {
  display: flex;
  align-items: center;
  gap: 5px;
}

#summary-addon .credit-title-free p {
  font-size: 16px;
  color: #0d1021;
}

#summary-addon .credit-title-free h5 {
  font-size: 16px;
  color: #0d1021;
  margin-top: 9px;
  font-weight: 400 !important;
}

#summary-addon .additional-package {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
}

#summary-addon .additional-package p {
  font-size: 13px;
  font-weight: 400;
  color: #595f79;
}

#summary-addon .additional-package .number {
  padding: 4px 8px;
  border-radius: 6px;
  background-color: #3b53d129;
  font-size: 14px;
  font-weight: 500;
  color: #4458c9;
  box-shadow: 0px 4px 14px 0px #00000021;
  display: inline-block;
  width: auto;
}
