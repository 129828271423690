.tab-container {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

#bulk-enrichment-files, #bulk-enrichment-file-upload {
  padding: 6px 19px;
  color: #595f79;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 18px;
  text-align: center;
  text-shadow: -1px 1px 0 0 #ffffff;
  border-radius: 8px;
}

#bulk-enrichment-files.active, #bulk-enrichment-file-upload.active {
  color: #3b53d1;
  font-weight: 500;
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 1px 3px 0 rgba(20, 24, 47, 0.12);
  border-radius: 6px;
  transition: all 0.4s ease;
}

#bulk-enrichment-files.active:hover, #bulk-enrichment-file-upload.active:hover {
  color: #3b53d1;
}
#bulk-enrichment-files:hover, #bulk-enrichment-file-upload:hover {
  color: #3e4358;
}
