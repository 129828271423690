.buttons-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons a {
  width: 80%;
  text-align: center;
}
