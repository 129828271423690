.enter-code-field .styles_react-code-input-container__tpiKG {
  width: 100% !important;
}
.enter-code-field .styles_react-code-input-container__tpiKG input:last-child {
  border-right-color: #d5d7e4;
}
.enter-code-field .styles_react-code-input-container__tpiKG input {
  font-family: inherit;
}

.vi__container {
  width: 100%;
  display: block;
}
.vi__character {
  height: 57px;
  width: 57px;
  margin: 0;
  margin-right: 10px;
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  display: inline-block;
  margin-right: 18px;
  text-align: center;
  outline: none;
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  color: #14182f;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  opacity: 0.7;
  border-color: #ccc;
  color: #ccc;
}
.vi__character--selected,
.vi__character--filled {
  color: black;
  border-color: #7390ce;
  border-width: 2px;
}

#confirm-account-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 25vh;
}

#confirm-account-wrapper .confirm-acc-title {
  margin-bottom: 32px;
}

#confirm-account-wrapper .confirm-acc-title h1 {
  font-size: 32px;
  font-weight: 600;
  color: #0a0a0c;
  line-height: 37.5px;
  text-align: center;
  letter-spacing: 0.01rem;
}

#confirm-account-wrapper .confirm-acc-title p {
  font-size: 16px;
  font-weight: 400;
  color: #414143;
  margin-bottom: 50px;
  text-align: center;
}

#confirm-account-wrapper .confirm-acc-title span {
  font-size: 16px;
  font-weight: 700;
  line-height: 20.16px;
  text-align: start;
  color: #414143;
}

#confirm-account-wrapper .confirm-acc-ctn .verif-code p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.16px;
  text-align: left;
  margin-bottom: 16px;
}

#confirm-account-wrapper .confirm-acc-ctn .bottom-ctn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#confirm-account-wrapper .confirm-acc-ctn .code-verification {
  height: 57px;
  margin-bottom: 32px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

#confirm-account-wrapper .confirm-acc-ctn .code-verification .code-loading {
  width: 20px;
  height: 20px;
  right: -2rem;
  position: absolute;
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.vi__container {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.vi__character {
  height: 57px;
  width: 57px;
  border-radius: 0%;
  margin: 0 10px 0 0;
  border: 2px solid transparent;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(20, 24, 47, 0.12);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: none;
  transition: border-color 0.2s ease-out;
  color: #000000;
  border-bottom: 1px black solid;
  opacity: 1;
}

.vi__character--selected,
.vi__character--filled {
  color: black;
  font-size: 30px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.01em;
  border-color: transparent;
  border-bottom: 1px black solid;
}

.vi__blinking-caret {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  color: #000000;
  animation: blink 1s step-start infinite;
  pointer-events: none;
}

#confirm-account-wrapper .confirm-acc-ctn .resend-code {
  all: unset;
  color: #5369df;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  text-transform: lowercase;
  cursor: pointer;
  line-height: 20.16px;
}

#confirm-account-wrapper .confirm-acc-ctn .resend-code:hover {
  color: #2b40b1;
  text-decoration: underline;
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.vi__container {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.vi__character {
  height: 57px;
  width: 57px;
  border-radius: 0%;
  margin: 0 10px 0 0;
  border: 2px solid transparent;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(20, 24, 47, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: none;
  transition:
    border-color 0.2s ease-out,
    color 0.2s ease-out;
  color: #000000;
  border-bottom: 1px black solid;
  font-size: 30px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.01em;
  position: relative;
  cursor: text;
}

.vi__character--selected::placeholder {
  animation: blink 2s step-start infinite;
  color: transparent;
}

/* Blinking cursor */
.vi__character--selected::after {
  content: '|';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 40px;
  font-weight: 100;
  color: #000000;
  animation: blink 2s step-start infinite;
  pointer-events: none;
}
