#feedback-list-container {
    padding: 8px;
    border-radius: 12px;
    border: 1px solid #ECECEC
}

#feedback-list-container.is-selected {
    border: 1px solid #4D58F3;
    background-color: #4D58F308;

}

#feedback-list-container .reason {
    margin-left: 30px;
}





