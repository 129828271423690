#files {
  height: 80vh;
}

#files .table-content {
  overflow-x: auto;
}

#files .table-content table {
  width: 100%;
  table-layout: fixed;
}

#files .table-content table thead {
  width: 100%;
}

#files .table-content table thead tr th:first-child,
#files .table-content table tbody tr td:first-child {
  width: auto;
  padding: 6px 8px 6px;
  max-width: 400px;
}

#files .table-content table thead tr th {
  text-align: center;
}

#files .loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

#files .loading-container img {
  height: 30px;
}

#files .permission-denied-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red ;
}

#files .no-data-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: 100%;
  text-align: center;
  line-height: 300px;
  color: #ccc;
  font-size: 20px;
}
