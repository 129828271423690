.home .fix-0-top {
  top: 0 !important;
}
.home-container {
  display: flex;
  justify-content: stretch;
}
.home .content-wrapper-ui {
  z-index: 0 !important;
}
.home-container .content-block {
  padding-left: 20px;
  padding-top: 3px;
  width: 100% !important;
}
#dashboard_tab .home-card {
  position: relative;
  height: 500px !important;
  min-height: 320px !important;
  padding: 20px;
}
#dashboard_tab .home-card .notification-list {
  height: 220px;
  overflow: auto;
}
#dashboard_tab .home-card .notification-list li {
  font-size: 16px;
}
#dashboard_tab .home-card .notification-list li h3 {
  font-size: 15px;
}
#dashboard_tab .home-card:hover {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
#dashboard_tab .home-card-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#dashboard_tab .home-card-center img,
#dashboard_tab .home-card-center h2,
#dashboard_tab .home-card-center p {
  margin-bottom: 20px;
}

.onBoarding-sideNav {
  position: relative;
  left: -7px;
  width: 450px;
  height: max-content;
  background: #fff;
  border-radius: 16px;
  padding: 30px;
}
.onBoarding-sideNav .skip-onboarding {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
}
.onBoarding-sideNav .skip-onboarding > img {
  width: 12px;
  cursor: pointer;
}
.onBoarding-sideNav .skip-onboarding > span {
  color: #5d73e1;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.onBoarding-sideNav .onBoarding-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ececec;
  border-radius: 8px;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
}
.refer-tag {
  background: #ffe9b3;
  border-radius: 5px;
  padding: 1px 4px;
}
.onBoarding-sideNav .onBoarding-item.validated {
  display: flex;
  padding: 10px;
  border: 1px solid #ececec;
  border-radius: 8px;
  margin-bottom: 20px;
  cursor: pointer;
  background: #3b53d199;
}
.onBoarding-sideNav .onBoarding-item.validated img {
  filter: brightness(1.8);
}
.onBoarding-sideNav .onBoarding-item.validated h3 {
  color: #fff !important;
}
.onBoarding-sideNav .onBoarding-item:hover {
  border-color: #6a77bb;
}
.onBoarding-sideNav .onBoarding-item h3 {
  font-size: 14px;
  color: #495377;
  margin-bottom: 0;
}
.onBoarding-sideNav .onBoarding-item .onBoarding-item-icon {
  padding: 0 20px 0 5px;
}
.onBoarding-home-icon-change-style {
  background: #fff !important;
  box-shadow: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  width: 60px !important;
}
.onBoarding-sideNav progress {
  -webkit-appearance: none;
  width: 100%;
}
.onBoarding-sideNav progress::-webkit-progress-bar {
  /* style rules */
  background: #ddd;
  border-radius: 5px;
  height: 10px;
}
.onBoarding-sideNav progress::-webkit-progress-value {
  border-radius: 5px;

  background: #595f79;
}
.onBoarding-sideNav .upgrade {
  background: #ececec90;
  color: #333;
  padding: 13px;
  border-radius: 8px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 500;
}
.onboarding-section {
  display: flex !important;
  justify-content: space-between;
  margin-left: 10px;
}
.onboarding-section .onboarding-wp {
  margin-top: 10px;
  padding-right: 20px;
  width: 100%;
}
.onboarding-section .onboarding-wp .onboarding-banner {
  background: lightcoral;
  border-radius: 4px;
  padding: 6px;
  color: #fff;
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
}
.onboarding-section .onboarding-wp .onboarding-banner i.fa-exclamation-triangle {
  margin-right: 5px;
}
.onboarding-section h2 {
  color: #495377;
  font-size: 29px;
  font-weight: bold;
  width: 100%;
}
.onboarding-section .right-sections {
  min-width: 260px;
  max-width: 260px;
}
.onboarding-section .right-sections section {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 20px;
}
.onboarding-section .right-sections section p {
  font-size: 20px;
}
.onboarding-section .onboarding-content {
  margin-top: 30px;
  width: 100%;
}
.onboarding-section .onboarding-content .video {
  width: 560px;
  height: 315px;
  background: #000;
  margin: 20px 0;
  border-radius: 17px;
  margin-bottom: 50px;
}
.onboarding-section .onboarding-content button {
  margin-top: 00px;
}
.onboarding-content.workspace {
  padding-right: 20px;
}
.onboarding-content.workspace h4 {
  font-size: 17px;
}
.-or- {
  width: 100%;
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #a9afc6;
  margin-top: 30px;
}
.-or- > span {
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  margin-top: -2px;
  font-weight: bold;
}
.onboarding-section .onboarding-content p {
  color: #595f79;
  width: 80%;
}
.onboarding-section .onboarding-content.workspace {
  color: #495377;
  font-size: 20px;
  margin-top: 20px;
}
.onboarding-section .onboarding-content.workspace .create-new-workspace-btns p {
  font-size: 15px;
  margin-top: 20px;
}
.onboarding-section .onboarding-content.workspace .create-new-workspace-btns button {
  background: rgba(83, 105, 223, 0.12);
  width: 100%;
  padding: 20px;
  color: #4d58f3;
  margin-top: 30px;
}
.onboarding-section .onboarding-content .companies-box {
  max-height: 350px;
  overflow: auto;
  padding: 0 20px;
}
.onboarding-section .onboarding-content .companies-box .company-box-item {
  width: 100%;
  height: 90px;
  background: #fff;
  margin: 20px 0;
  border-radius: 16px;
  display: flex;
  align-items: center;
}
.onboarding-section .onboarding-content .companies-box .company-box-item .company-icon {
  padding: 10px 20px;
}
.onboarding-section .onboarding-content .companies-box .company-box-item .company-icon img {
  width: 50px;
}
.onboarding-section .onboarding-content .companies-box .company-box-item .company-data {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.onboarding-section
  .onboarding-content
  .companies-box
  .company-box-item
  .company-data
  *:first-letter {
  text-transform: capitalize;
}
.onboarding-section .onboarding-content .companies-box .company-box-item .company-data p > span {
  font-size: 13px;
  padding: 5px;
  border-radius: 8px;
  background: #f5f5f9;
}

.onboarding-section .onboarding-content .companies-box .company-box-item .company-request {
  display: flex;
  align-items: center;
  padding: 10px 20px;
}
.onboarding-section .onboarding-content .companies-box .company-box-item .company-request button {
  margin: 0;
  min-width: max-content;
  color: #4d58f3;
  font-size: 13px;
}
.onboarding-section
  .onboarding-content
  .companies-box
  .company-box-item
  .company-request
  button
  span {
  margin-right: 5px;
}
.credit-earned {
  font-size: 14px;
  margin-bottom: 10px;
}
.onboarding-section .subtitile {
  background: #e6e6e660;
  color: #595f79;
  width: fit-content;
  padding: 10px;
  border-radius: 8px;
}
.onboarding-section .subtitile img {
  margin-right: 5px;
}
.upgrade-section {
  height: max-content !important;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.upgrade-section img {
  position: absolute;
  top: 0;
  right: -25px;
  left: 30px;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.upgrade-section .content {
  position: relative;
  z-index: 2;
}
.upgrade-section p {
  background: linear-gradient(270.05deg, #ff327c 37.96%, #f17027 97.75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 10px;
  width: 85%;
}
.onboarding-content .invites-container {
  display: flex;
  height: 200px;
}
.onboarding-content .invites-container > div {
  min-height: 200px;
  height: max-content;
  width: 50%;
  background: #000;
  border-radius: 16px;
  padding: 20px;
  border: 1px solid transparent;
  cursor: pointer;
}
.onboarding-content .invites-container > div.gold-invites {
  /*
  background: rgba(255, 233, 179, 0.3);
  rgba(241, 112, 39, 1)
rgba(241, 160, 39, 1)
rgba(255, 233, 179, 0.2)
  */
  background: linear-gradient(to right bottom, #f17027 10%, #f3b554 95%);
  margin-right: 10px;
}
.onboarding-content .invites-container p {
  width: 100%;
  min-width: 90%;
}
.onboarding-content .invites-container > div.gold-invites > * {
  color: #fff !important;
}
.onboarding-content .invites-container > div.gold-invites small {
  color: #fff !important;
}
.onboarding-content .invites-container > div h3 {
  margin-top: 20px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.onboarding-content .invites-container > div.gold-invites h3 {
  color: #fff;
}
.onboarding-content .invites-container > div.gold-invites .dots > span {
  background: #ffffff77;
}

.onboarding-content .invites-container > div.normal-invites h3 {
  color: #4d58f3;
}
.onboarding-content .invites-container > div.normal-invites {
  background: #efeff8;
  margin-left: 10px;
}
.onboarding-content .invites-btn {
  margin-top: 20px !important;
}
.onboarding-content .invites-container .invites-left {
  display: flex;
  align-content: center;
  position: relative;
}
.onboarding-content .invites-container .invites-left i.fa-medal {
  position: absolute;
  right: 20px;
  font-size: 25px;
  color: #fff;
}
.onboarding-content .invites-container .invites-left .dots span {
  width: 11px;
  height: 11px;
  display: inline-block;
  background: #d8d8d8;
  border-radius: 50%;
  margin: 0 2px;
}
.onboarding-content .invites-container .invites-left .dots {
  align-items: center;
  display: flex;
}
.onboarding-content .invites-container .invites-left small {
  margin-left: 10px;
  color: #555;
  font-size: 13px;
}

.onboarding-content .emails-tags {
  margin-top: 20px;
  min-height: 50px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.onboarding-content .emails-tags > span {
  padding: 5px 10px;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
}
.onboarding-content .emails-tags > span.gold {
  background: rgba(255, 233, 179, 0.3);
  color: #f17027;
}
.onboarding-content .emails-tags > span.standard {
  background: #efeff8;
  color: #4d58f3;
}

.onboarding-content textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  border: #f3f3f3;
  border-radius: 16px;
  padding: 10px 20px;
  outline: none;
}
.onboarding-content textarea::placeholder {
  color: #999;
  font-size: 17px;
}
.onboarding-content .loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
.onboarding-content .loader img {
  width: 50px;
}
.onboarding-section .onboarding-content .integration-box {
  height: 60vh;
  overflow: auto;
  padding: 0 20px;
}
.onboarding-section .onboarding-content .integration-box .integration-box-item {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.onboarding-section .onboarding-content .integration-box .integration-box-item .integration-icon {
  padding: 20px 40px 20px 20px;
}
.onboarding-section
  .onboarding-content
  .integration-box
  .integration-box-item
  .integration-icon
  img {
  width: 50px;
}
.onboarding-section .onboarding-content .integration-box .integration-box-item .integration-data {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.onboarding-section
  .onboarding-content
  .integration-box
  .integration-box-item
  .integration-data
  *:first-letter {
  text-transform: capitalize;
}
.onboarding-section .onboarding-content .integration-box .integration-box-item .integration-data p {
  font-size: 14px;
  padding: 0;
  border-radius: 8px;
  background: #f5f5f9;
}

.onboarding-section
  .onboarding-content
  .integration-box
  .integration-box-item
  .integration-request {
  display: flex;
  align-items: center;
  padding: 20px;
}
.onboarding-section
  .onboarding-content
  .integration-box
  .integration-box-item
  .integration-request
  button {
  margin: 0;
  min-width: max-content;
  padding: 0 30px;
  width: 170px;
}
.onboarding-section
  .onboarding-content
  .integration-box
  .integration-box-item
  .integration-request
  button
  span {
  margin-right: 10px;
}
.onboarding-content .invitations-received {
  background: #ffff;
  padding: 10px 15px;
  border-radius: 16px;
  margin-top: 0;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.onboarding-content .invitations-received img {
  width: 30px;
  border-radius: 50%;
  border: 1px solid #ccc;
  padding: 2px;
  margin-right: 5px;
}
.onboarding-content .invitations-received button {
  margin: 0;
  margin-left: 20px;
  color: #4d58f3;
  font-size: 18px;
  font-weight: 500;
}
.onboarding-content .invitations-received button:last-child {
  color: lightsalmon;
}
.onboarding-content .input-tags-invite-container .input-tags {
  background: #fff;
  border-radius: 8px;
  padding: 5px;
  border: 1px solid #ccc;
  margin: 30px 0;
}
.onboarding-content .input-tags-invite-container .input-tags input {
  padding: 10px;
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
}
.onboarding-content .input-tags-invite-container .input-all-tags {
  top: 3px;
}
.onboarding-content .input-tags-invite-container.is-gold span.input-tag {
  background: rgba(255, 233, 179, 0.3);
  color: #f17027;
}
.onboarding-content .input-tags-invite-container.is-standar span.input-tag {
  background: #efeff8;
  color: #4d58f3;
}
.onboarding-content .invitations-status {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 12px;
}
.onboarding-content .pending-invitations {
  width: 40%;
  min-width: min-content;
}
.onboarding-content .pending-invitations.accepted {
  margin-left: 0px;
  padding-left: 20px;
  border-left: 1px solid #ccc;
  width: 60% !important;
}
.onboarding-content .pending-invitations p {
  font-size: 17px;
  font-weight: bold;
  color: #555;
}

.onboarding-content .pending-invitations h3 {
  font-size: 17px;
  color: #495377;
}
.onboarding-content .pending-invitations .emails-tags {
  padding: 0;
  margin: 0;
}
.onboarding-content .pending-invitations.accepted .emails-tags span {
  padding-right: 0;
}
.onboarding-content .pending-invitations .emails-tags span img {
  margin-left: 10px;
  position: relative;
}

.onboarding-content .close-tag {
  background: #555;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -5px;
}
.onboarding-content .div-message {
  background: red;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
  position: relative;
}
.onboarding-content .div-message > div {
  margin-right: 10px;
}
.onboarding-content .div-message i.fa-times {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
}
.onboarding-content .close-tag img {
  width: 7px;
  margin: 0 !important;
}
@media screen and (max-width: 1500px) {
  .onboarding-section .onboarding-content.invitations p {
    color: #595f79;
    width: 100% !important;
    font-size: 0.9em !important;
  }
}
