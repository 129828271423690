.table-wrap .head_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  padding: 20px 16px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  background: #fff;
  z-index: 9;
  border-radius: 8px;
}

@media only screen and (max-width: 991px) {
  .table-wrap .head_block {
    padding: 20px 10px;
  }
}

.table-wrap .head_block .left_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  margin-right: auto;
}

.table-wrap .head_block .left_block .title-box {
  margin-right: 32px;
}

@media only screen and (max-width: 991px) {
  .table-wrap .head_block .left_block .title-box {
    margin-right: 15px;
  }
}

.table-wrap .head_block .left_block .title-box p {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.48px;
  line-height: 32px;
  color: #0d1829;
}

@media only screen and (max-width: 991px) {
  .table-wrap .head_block .left_block .title-box p {
    font-size: 16px;
  }
}

.table-wrap .head_block .sorting-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
}

.table-wrap .head_block .sorting-block label {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.32px;
  line-height: 18px;
  color: #595f79;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 991px) {
  .table-wrap .head_block .sorting-block label {
    font-size: 14px;
  }
}

.table-wrap .head_block .sorting-block.table-listing {
  margin-left: 32px;
}

@media only screen and (max-width: 991px) {
  .table-wrap .head_block .sorting-block.table-listing {
    margin-left: 20px;
  }
}

.table-wrap .head_block .right_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-items: center;
}

.table-wrap .head_block .right_block .sorting-block.table-listing {
  margin-left: 0;
  margin-right: 32px;
}

.table-wrap .head_block .right_block .more-btn {
  margin-left: 16px;
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  vertical-align: middle;
  text-align: center;
  transsition: 0.2s ease-in-out;
}

@media only screen and (max-width: 991px) {
  .table-wrap .head_block .right_block .more-btn {
    margin-left: 15px;
  }
}

.table-wrap .head_block .right_block .more-btn span {
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #797f99;
  border-radius: 10px;
  margin: 1.5px;
}

.table-wrap .head_block .right_block .more-btn:hover {
  -webkit-box-shadow: 0 0 0 4px rgba(78, 99, 216, 0.1);
  box-shadow: 0 0 0 4px rgba(78, 99, 216, 0.1);
}
.search-input {
  border: 1px solid #bbb7c8;
  border-radius: 12px;
  padding: 0 10px;
  font-size: 16px;
  letter-spacing: -0.18px;
  line-height: 20px;
  height: 35px;
  color: #797f99;
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0 20px 0;
}

.all-leads__ctn {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
}
