.kaspr-form-text-error {
  position: relative;
  top: -20px;
  color: #e23f74;
  margin: 0 auto !important;
  width: 70%;
}
.onboard-list-container {
  display: flex;
  align-items: center;
  margin-top: 0px;
}
.select-purpose{
  width: 100%;
  border: 1px solid #d5d7e4;
  borderRadius: 5px;
  padding:10px;
  box-shadow:0 1px 2px 0 rgba(20, 24, 47, 0.12);
  cursor:pointer;
  border-radius: 5px;
}