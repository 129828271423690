.custom-select-picker {
    position: relative;
    padding: 0;
    z-index: 10;
}
.custom-select-picker .selected-label {
    border: none;
    color: #3B53D1;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.12px;
    line-height: 18px;
    padding-left: 5px;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    min-width: 100px;
    z-index: 0;
    position: relative;




  /*  height: 40px;
    width: 65px;
    border-radius: 10px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 0 2px 0 rgba(20, 24, 47, 0.12), 0 2px 4px 0 rgba(20, 24, 47, 0.1);
    box-shadow: 0 0 2px 0 rgba(20, 24, 47, 0.12), 0 2px 4px 0 rgba(20, 24, 47, 0.1);
    text-align: center;*/
}
.custom-select-picker .selected-label:first-letter {
    text-transform: capitalize;
}
.custom-select-picker .labels {
    position: absolute ;
    background: #fff;
    left:5px;
   box-shadow:  1px 2px 0 rgba(20, 24, 47, 0.12), 0 2px 4px 0 rgba(20, 24, 47, 0.1), 0 4px 8px 0 rgba(20, 24, 47, 0.1);    width: 120px;
    margin-top: 10px;
    border-radius: 6px;
    background: #fff;
}
.custom-select-picker .labels ul {
padding: 0;
}
.dropdown ul {
    padding: 0;
}
.custom-select-picker .labels .caps {
 text-transform: capitalize;
}
.custom-select-picker .labels ul li {
    padding: 5px;
    padding-left: 10px;
    margin: 7px 0;
    cursor:pointer;
    color:#555
}
.selected-label-org {
    color :#999 !important;
    border: 1px solid #ccc !important;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    z-index: 0;
    position:relative;
}
