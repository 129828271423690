.connection-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  background-color: #f5f5f9;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition: all 0.5s ease;
  margin-bottom: 20px;
  padding: 20px;
}

.connection-modal-header .close-button {
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.connection-modal-header .modal-inner-header {
  display: flex;
  align-items: center;
  gap: 30px;
}

.connection-modal-header .profile-img-block img {
  width: 118px;
  border-radius: 20px;
}

.connection-modal-header .profile-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.connection-modal-header .username {
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.12px;
  text-align: left;
}

.connection-modal-header .designation {
  font-size: 0.85rem;
  color: #6c757d;
}

.connection-modal-header .tool-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
  letter-spacing: 0.12px;
  color: #797f99;
}

.connection-modal-header .icons-container {
  display: flex;
  gap: 20px;
}

.connection-modal-header .icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.connection-modal-header .icons p {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
