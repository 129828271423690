.delete-lead-container {
    width: 100%;
    background: rgba(200,50,50,.1);
    position: fixed;
    bottom:0;
    left:0;
    z-index: 100;
    top:260px;
    padding-top: 0px;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease;

}
.slide-up-toggle {
    visibility: visible;
    opacity: 1;
}

.delete-lead {
    width: 90%;
    margin:0 auto;
    transition: all .3s ease;
    z-index: 20;
    top: 50px;
    position: relative;
}
.delete-lead.popup-slide-up {
    top:0px;
    opacity: 1;
}
.delete-lead h3 {
    font-size: 17px;
    color: #ca5372;
}
.delete-lead .delete-lead-content {
    background: #fff;
    padding : 15px;
    width: 100%;
    border : 1px solid rgba(202, 83, 114, 0.42);
    box-shadow: 1px 1px 3px rgba(0,0,0,.2);
    border-radius: 10px;
  /*  border-top: 1px solid rgba(202, 83, 114, 0.42);
    border-bottom: 1px solid rgba(202, 83, 114, 0.42);*/


}
.delete-lead .delete-lead-content p {
    font-size: 17px;
    color: #666;
}
.delete-lead .delete-lead-btns {
    margin-top: 20px;
   display: flex;
    justify-content: flex-end;
}
.delete-lead .delete-lead-btns button{
   margin:0 10px;
}
.delete-lead .delete-lead-btns button.btn1 {
    background: #ca5372;
}

.delete-lead-content .delete-lead-message {
    color:green;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}
.delete-lead-content .delete-lead-message .fa-check-circle {
    margin-right: 10px;

}
.add-blur {
    filter: blur(2px);
    transition: all .5s ease;
}