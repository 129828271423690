.all-lists-nav-container {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.all-lists-top-nav {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
}

.all-lists-top-nav span {
  color: #757ca3;
}

.all-lists-top-nav p {
  color: #33374d;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.1px;
}

.all-lists-nav-container__ctn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.all-lists-nav-container__left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.all-lists-nav-container__left__search {
  position: relative;
  width: 400px !important;
}

.all-lists-nav-container__left__search input {
  width: 100%;
}

.all-lists-nav-container__left__search:after {
  position: absolute;
  content: '';
  background: url('../../../../../public/assets/images/lead-search-icon.svg') no-repeat;
  width: 24px;
  height: 24px;
  left: 10px;
  top: 8px;
  background-size: 24px;
}

/* Back button */

.all-lists-nav-container .btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
}

.all-lists-back-btn {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.1px;
  color: #757ca3;
  padding-left: 0.5rem;
}

.all-lists-nav-container .btn-wrapper:hover .all-lists-back-btn {
  text-decoration: underline;
}

.all-lists_dropdown .search-input {
  margin: 0 1rem 1rem 1rem;
}
