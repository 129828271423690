#plan_header .plan_header__title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0px;
}
#plan_header .plan_header__title h1 {
  text-align: center;
  font-size: 36.1px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 110.803% */
  background: linear-gradient(90deg, #6366f1 0%, #d946ef 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 24px;
  
}
#plan_header .plan_header__title p {
  color: var(--Grey---600, #757ca3);
  text-align: center;
  font-size: 19.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 143.59% */
}
#plan_header .plan_header__title .plan_header__switch {
  margin:20px 0;
}
#plan_header .plan_section {
  display: flex;
  align-items: center;

  padding: 0px 0;
  padding-right: 0;
  width: 100%;
  justify-content: space-between;
  font-weight: 500;
}
#plan_header .buy-credits-pack {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  border: 1px solid #e66d6d;
  padding: 10px 30px;
  border-radius: 5px;
  background: linear-gradient(96.13deg, #e66d6d -17.49%, #db48c4 150.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
