#vat-section {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

#vat-section .vat-section-wrapper {
  width: 100%;
}

#vat-section .vat-section-wrapper .vat-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#vat-section .vat-section-wrapper .vat-row .vat-amount {
  color: #696969;
}
