.input-container {
  position: relative;
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
}

.input-container label {
  position: absolute;
  top: 10%;
  left: 12px;
  font-size: 14px;
  color: #5369df;
  pointer-events: none;
  background-color: white;
  padding: 0 2px;
  font-weight: 500;
}

.input-container input {
  width: 100%;
  padding: 30px 12px 8px 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  background: white;
  color: #14182f;
}

.input-container input::placeholder {
  color: transparent;
}

.input-container input:focus {
  border: 1px solid #5369df;
  color: #14182f;
}
