
.leads-detail-wrapper .header-blk {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
@media (max-width: 991px) {
    .leads-detail-wrapper .header-blk {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}
.leads-detail-wrapper .activity-content .ac-card {
    background: #F5F5F9;
    border: 1px solid #EFEFF8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px 12px 16px 35px;
    position: relative;
    margin-bottom: 16px;
}
.leads-detail-wrapper .activity-content .ac-card .check-icon {
    background: url(assets/images/ic-seprite.svg);
    width: 14px;
    height: 11px;
    position: absolute;
    left: -8px;
    top: 3px;
    background-position: -264px -9px;
}
.leads-detail-wrapper .activity-content .ac-card .sub-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #242946;
    margin-bottom: 8px;
}
.leads-detail-wrapper .activity-content .ac-card .desc {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #595F79;
}
.leads-detail-wrapper .activity-content .ac-card .desc .orange-text {
    color: #F96334;
}
.leads-detail-wrapper .activity-content .ac-card .desc .underline {
    border-bottom: 1px dashed #B5BACF;
    padding-bottom: 2px;
}
.leads-detail-wrapper .activity-content .ac-card .date-ui {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    text-align: right;
    letter-spacing: 0.24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    color: #8E96B6;
}
.leads-detail-wrapper .filter-by .cstm-select.date-sorting .bootstrap-select button {
    color: #797F99;
}
.leads-detail-wrapper .filter-by .cstm-select.date-sorting .bootstrap-select .dropdown-toggle + .dropdown-menu {
    -webkit-transform: translate3d(-70px, 21px, 0px) !important;
    transform: translate3d(-70px, 21px, 0px) !important;
    top: 0 !important;
}
.leads-detail-wrapper .form-group {
    position: relative;
    z-index: 1;
    /*&:after{
        position: absolute;
        content: "";
        top: -2px;
        z-index: -1;
        left: -5px;
        border-radius:8px;
        padding: 8px 10px;
        height: 114%;
        width: 100%;
        background: #F5F5F9;
        transition:0.2s ease-in-out;
        opacity:0;
    }*/
    /*&:hover{
        &:after{
            opacity:1;
        }
    }*/
}

.s-title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #8E96B6;
}

.notes-card {
    background: #F5F5F9;
    border: 1px solid #EFEFF8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 14px 14px 16px;
    margin-bottom: 20px;
}
.notes-card .note-postby {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.notes-card .note-postby figure {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 0;
}
.notes-card .note-postby figure img {
    width: 100%;
    height: 100%;
    -o-object-position: center;
    object-position: center;
    -o-object-fit: cover;
    object-fit: cover;
}
.notes-card .note-postby p {
    width: calc(100% - 36px);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #242946;
    padding-left: 12px;
}
.notes-card .date-ui {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    text-align: right;
    letter-spacing: 0.24px;
    color: #8E96B6;
}
.notes-card .desc {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #242946;
    margin-top: 28px;
}

#cke_editor1 {
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.1);
    box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.1);
    border-radius: 8px !important;
    border: none;
}

.task-tab .new-task-btn {
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.1);
    box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.1);
    border-radius: 8px;
    padding: 12px 16px 12px 50px;
    width: 100%;
    text-align: left;
    position: relative;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}
.task-tab .new-task-btn:hover {
    -webkit-box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.16), 0px 2px 4px rgba(20, 24, 47, 0.16);
    box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.16), 0px 2px 4px rgba(20, 24, 47, 0.16);
}
.task-tab .new-task-btn:before {
    position: absolute;
    content: "";
    background: url(assets/images/ic-seprite.svg);
    width: 22px;
    height: 20px;
    background-position: -81px -38px;
    left: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.task-card-wrapper .task-card {
    background: #F5F5F9;
    border: 1px solid #EFEFF8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 14px 14px;
    margin:5px 0;
}
.task-card-wrapper .task-card .top-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.task-card-wrapper .task-card .bottom-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.task-card-wrapper .task-card .bottom-bar .left-ui {
    width: calc(100% - 130px);
    padding-left: 38px;
    position: relative;
}
.task-card-wrapper .task-card .bottom-bar .left-ui:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    background: url(assets/images/ic-seprite.svg);
    background-position: -4px -71px;
    width: 26px;
    height: 26px;
}
.task-card-wrapper .task-card .bottom-bar .title-ui {
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #242946;
    padding-top: 4px;
}
.task-card-wrapper .task-card .bottom-bar .desc {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #797F99;
}
.task-card-wrapper .task-card .bottom-bar .date-ui {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #797F99;
}
.task-card-wrapper .task-card .task-legend {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
    position: relative;
    padding-left: 12px;
}
.task-card-wrapper .task-card .task-legend:before {
    position: absolute;
    content: "";
    width: 6px;
    height: 16px;
    border-radius: 5px;
    top: -1px;
    left: 0;
}
.task-card-wrapper .task-card .task-legend.reminder-tag {
    color: #217EEA;
}
.task-card-wrapper .task-card .task-legend.reminder-tag:before {
    background: #4B9EFF;
}
.task-card-wrapper .task-card .task-legend.email-tag {
    color: #C6910B;
}
.task-card-wrapper .task-card .task-legend.email-tag:before {
    background: #C6910B;
}
.task-card-wrapper .task-card .task-legend.todo-tag {
    color: #EE6338;
}
.task-card-wrapper .task-card .task-legend.todo-tag:before {
    background: #EE6338;
}
.task-card-wrapper .task-card .task-legend.call-tag {
    color: #1AA251;
}
.task-card-wrapper .task-card .task-legend.call-tag:before {
    background: #1AA251;
}
.task-card-wrapper .task-card .mbtn {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.task-card-wrapper .task-card .mbtn:before {
    position: absolute;
    content: "";
    background: url(assets/images/ic-seprite.svg);
    width: 24px;
    height: 15px;
    top: 0;
    left: 0;
    background-position: -42px -74px;
    z-index: 1;
}

#add-task-modal .inner-content {
    padding: 0 24px 24px;
}
#add-task-modal .inner-content .form-group .form-control {
    background: #F5F5F9;
    border: 1px solid #D5D7E4;
    border-radius: 8px;
    height: 44px;
}
#add-task-modal .inner-content .add-note-blk {
    margin-bottom: 24px;
}
#add-task-modal .inner-content .addnote-btn {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #3B53D1;
}
#add-task-modal .inner-content .addnote-btn .ic-note {
    position: relative;
    background:url("assets/images/ic-seprite.svg");
    width: 24px;
    height: 24px;
    margin-right: 13px;
    background-position: -79px -71px;
}
#add-task-modal .modal-inner-header .sm-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.186667px;
    color: #797F99;
}
#add-task-modal .modal-inner-header .sm-title strong {
    color: #797F99;
    font-weight: bold;
}
#add-task-modal .datepicker-ui {
    position: relative;
}
#add-task-modal .datepicker-ui:after {
    content: "";
    background: url(assets/images/ic_arrow_down.svg) no-repeat;
    width: 10px;
    height: 10px;
    border: none;
    margin: 0;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
#add-task-modal .datepicker-ui input {
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.1);
    box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.1);
    border-radius: 8px;
}
#add-task-modal .timepicker-ui {
    position: relative;
}
#add-task-modal .timepicker-ui:after {
    content: "";
    background: url(assets/images/ic_arrow_down.svg) no-repeat;
    width: 10px;
    height: 10px;
    border: none;
    margin: 0;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
#add-task-modal .timepicker-ui input {
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.1);
    box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.1);
    border-radius: 8px;
}
#add-task-modal .modal-footer .btn1,
#add-task-modal .modal-footer .btn2 {
    height: 48px;
    padding: 0 30px;
    line-height: 48px;
}
#add-task-modal .modal-footer .btn2 {
    color: #797F99;
}
#add-task-modal .modal-footer .btn2:hover {
    color: #242946;
}

.cstm-selectepicker .bootstrap-select {
    width: 100% !important;
}
.cstm-selectepicker .bootstrap-select .dropdown-toggle {
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.1);
    box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.1);
    border-radius: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #242946;
    padding: 11px 10px;
}
.cstm-selectepicker .bootstrap-select .dropdown-toggle:after {
    background: url(assets/images/ic_arrow_down.svg) no-repeat;
    width: 10px;
    height: 10px;
    border: none;
    margin: 0;
    position: absolute;
    right: 8px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.cstm-selectepicker .bootstrap-select .dropdown-toggle:focus {
    outline: none !important;
}
.cstm-selectepicker .bootstrap-select .dropdown-toggle + .dropdown-menu {
    width: 100% !important;
    padding: 20px;
    border-radius: 12px;
    border: none;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.1);
    box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.1);
}
.cstm-selectepicker .bootstrap-select .dropdown-toggle + .dropdown-menu li a {
    color: #242946;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 18px;
    text-shadow: -1px 1px 0 0 #FFFFFF;
    padding: 8px 0px;
    display: block;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}
.cstm-selectepicker .bootstrap-select .dropdown-toggle + .dropdown-menu li a.active {
    background: none;
}
.cstm-selectepicker .bootstrap-select .ic-reminder {
    position: relative;
    padding-left: 10px;
}
.cstm-selectepicker .bootstrap-select .ic-reminder:before {
    background: #4B9EFF;
    border-radius: 5px;
    width: 6px;
    height: 16px;
    position: absolute;
    content: "";
    top: 2px;
    left: 0;
}
.cstm-selectepicker .bootstrap-select .ic-todo {
    position: relative;
    padding-left: 10px;
}
.cstm-selectepicker .bootstrap-select .ic-todo:before {
    background: #EE6338;
    border-radius: 5px;
    width: 6px;
    height: 16px;
    position: absolute;
    content: "";
    top: 2px;
    left: 0;
}
.cstm-selectepicker .bootstrap-select .ic-call {
    position: relative;
    padding-left: 10px;
}
.cstm-selectepicker .bootstrap-select .ic-call:before {
    background: #1AA251;
    border-radius: 5px;
    width: 6px;
    height: 16px;
    position: absolute;
    content: "";
    top: 2px;
    left: 0;
}

.white-card {
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.12), 0px 4px 12px rgba(36, 41, 70, 0.16);
    box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.12), 0px 4px 12px rgba(36, 41, 70, 0.16);
    border-radius: 12px;
    padding: 24px;
}
.contact-fields-container {
    /* height: 700px;
        overflow: auto;*/

}
.contact-infos-container{
    min-height: 500px;
}
#company_info,
#contact_info{
    display: none;

}
#company_info.show,
#contact_info.show{
    display: block;
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 250ms;
    animation-duration: 250ms;
}

.body-content-container {
    overflow: hidden;
    position: relative;
    height: 100%;
}
.btns-leadsdetails-group {
    position: fixed;
    z-index: 100;
    bottom:0;
    background: #fff;
    width:400px;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: all 0.3s ease;
    border-top: 1px solid #eee;
}
.btns-leadsdetails-group  button {
    margin-left: 10px;
}

.btns-leadsdetails-group .fields-changed-container {
    margin-left: 20px;
    font-size: 12px;
    color: #555;
    border-left: 1px solid #ccc;
    padding-left: 10px;
}
.btns-leadsdetails-group .fields-changed-container p {
    margin-bottom: 2px;
}
.btns-leadsdetails-group .fields-changed {
    background: #ccc;
    color: #555;
    margin-right:2px;
    padding: 2px 7px;
    border-radius: 5px;
    background-color: rgba(255, 196, 150, 0.66);
    color: #ca5f1a;
    font-family: "Roboto", sans-serif;
}
