
.fieldactions-container {
    position: relative;
}
.fieldactions-container .fieldactions-icons{
    position: absolute;
    right: 10px;
    background: #fff;
    padding: 0 3px;
    border-radius: 5px;
    visibility: hidden;
    transition: opacity .3s ease;
    top:-15px;
    opacity: 0;
}
.fieldactions-container:hover .fieldactions-icons {
    visibility: visible;
    border: 1px solid #eee;
    opacity: 1;
}
.fieldactions-container .fieldactions-icons img {
    margin:0 3px;
    cursor: pointer;
    width: 15px;
}
.fieldactions-container .fieldactions-icons img:hover{
    filter : contrast(0.5);
}