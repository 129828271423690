.custom-switch {
    display: flex;
    align-items: center;
    position: relative;

}
.custom-switch .switch-btn{
  height: 20px!important;

}
.custom-switch .switch-btn input:checked {
    background-color: #33374D !important;
    -webkit-box-shadow: inset 0 1px 2px 0 rgba(13, 16, 33, 0.24);
    box-shadow: inset 0 1px 2px 0 rgba(13, 16, 33, 0.24);
    outline: none;
    display: flex;
  }
  .custom-switch  .switch-btn input {
    margin: 0 16px;
  }