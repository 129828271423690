#leads_panel,
#waitingList_tab {
  display: none;
}
#leads_panel.show,
#waitingList_tab.show {
  display: block;
}

#leads_panel .leads-table-footer {
  padding: 1rem;
  display: flex;
  justify-content: end;
}

.table-wrap-overflow {
  margin-bottom: 70px;
  position: relative !important;
  overflow: visible !important;
  white-space: nowrap;
}
