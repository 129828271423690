.profile-infos {
  padding: 0px;
  display: flex;
  position: relative;
}
.profile-infos .left-section {
  width: 300px;
  /*
    background: #ca5372;
    */
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.profile-infos .left-section .profile-img {
  width: 150px;
  height: 150px;
  /* background: #ffd2dd;*/
  background: #f8f9fa;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.profile-infos .left-section .change-picture {
  color: #5369df;
  margin-top: 15px;
  cursor: pointer;
  font-weight: 500;
}
.profile-infos .left-section .change-picture i {
   margin-right: 5px;
}
.profile-infos .left-section .profile-img img {
  width: 50px;
}

.profile-infos .right-section {
  padding: 0px 10px;
  padding-bottom: 40px;
  width: 45%;
  display: flex;
  justify-items: center;
}
.profile-infos .right-section .fields {
  width: 50%;
  padding: 0 0px;
}
.profile-infos .right-section .fields:first-child{
    margin-right: 30px;
}
.profile-infos .fields-form-group {
  width: 100%;
  margin: 10px 0;
  cursor: text;
  height: 50px;
}
.profile-infos .fields-form-group {
    height:50px;
    display: flex;
    flex-direction: column;
}
.profile-infos .fields-form-group label {
  font-style: normal;
  font-size: 14px;
  color: #797f99;
  margin: 0;
  font-weight: 400;
}
.profile-infos .fields-form-group .edit-user-field {
  width: 100%;
  position: relative;
  margin-top: 0;
  padding: 0;
}
.profile-infos .fields-form-group .edit-user-field input,
.profile-infos .fields-form-group .edit-user-field .field_value {
  font-size: 17px;
  font-weight: 500;
}

.profile-infos .buttons-saveAndCancel {
  position: absolute;
  bottom: 0;
  right: 0;
}
.profile-infos .buttons-saveAndCancel button {
  width: 120px;
  margin: 10px;
}
.profile-infos .password-change {

}
.profile-infos .password-change:hover {
    border-bottom: 1px solid #ccc;
}
.profile-infos .password-change:hover .edit_user-icon {
    display: block;
}
.profile-infos .edit_user-icon {
    position: absolute;
    top:-5px;
    right: 0;
    display: none;
}
.profile-infos .edit_user-icon img {
    width: 15px;
}
.edit-user-field {
    position: relative;
}
