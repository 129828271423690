#christmas-banner {
  width: 100%;
  background: linear-gradient(90deg, #e56c6c 0%, #da47c4 100%);
  height: 51px;
  position: sticky;
  z-index: 20;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#christmas-banner .christmas-inner-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
  width: 100%;
  height: 100%;
}

#christmas-banner .reduction-tag {
  background-color: #ffffff;
  padding: 6px 8px;
  border-radius: 44px;
}

#christmas-banner .reduction-tag p {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  color: #e15d93;
}

#christmas-banner .limited-time-offer {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}

#christmas-banner button {
  padding: 6px 8px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
}

#christmas-banner button:hover {
  background: #ffffff33;
}

#christmas-banner .ic-cross-banner {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}
