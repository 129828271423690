.responsive-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
}

.responsive-text FormattedMessage {
  max-width: 100%;
  font-size: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 600px) {
  .responsive-text FormattedMessage {
    font-size: 0.8em;
  }
}
